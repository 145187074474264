export default function radioButtonsToggler (nodeList) {
  // the nth checked radio button with class 'toggle' 
  // toggles the 'hide' class of the nth element with class 'visibility-toggled-by-radio-button'
  // within the container 'wrapper'
  // and hides the other elements marked to be toggled
  if (nodeList.length === 0) return;

  nodeList.forEach( (wrapper) => {
    let radioInputs, hiddenElements
    if (wrapper.querySelectorAll('.visibility-toggled-by-radio-button .radio-buttons-toggler').length > 0) {
      radioInputs = wrapper.querySelectorAll('input[type=radio].toggle:not(.visibility-toggled-by-radio-button input[type=radio].toggle)')
      hiddenElements = wrapper.querySelectorAll('.visibility-toggled-by-radio-button:not(.visibility-toggled-by-radio-button .visibility-toggled-by-radio-button)')
    }
    else {
      radioInputs = wrapper.querySelectorAll('input[type=radio].toggle')
      hiddenElements = wrapper.querySelectorAll('.visibility-toggled-by-radio-button')
    }
    hiddenElements.forEach( (e) => {e.classList.add('hide')})
    radioInputs.forEach( (input, index) => {
      if (input.checked) {
        hiddenElements[index].classList.remove('hide')
      }
      input.addEventListener('change', event => {
        if (input.checked) {
          hiddenElements.forEach( (e) => {e.classList.add('hide')})
          hiddenElements[index].classList.remove('hide')
        }
      })
    })
  })
}
