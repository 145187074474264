import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Link,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  HighlightOff,
  Star,
} from '@mui/icons-material'

export const ComparisonBar = ({items, handleRemoveFromComparison, compareLink}) => {

  const open = Boolean(items.length > 0)
  
  return (
    <Drawer
      anchor="bottom"
      open={open}
      ModalProps={{
        disableAutoFocus: true,
        disableEnforceFocus: true,
        disableEscapeKeyDown: true,
        disablePortal: true,
        disableRestoreFocus: true,
        disableScrollLock: true,
        hideBackdrop: true,
      }}
      style={{position: 'unset'}}
      variant="temporary"
    >
      <Container
        maxWidth={false}
      >
        <Box
          display="flex"
        >
          <Grid
            container
            spacing={0}
            style={{
              maxHeight: '200px',
              overflowX:'auto'
            }}
          >
            {items.map( (item, i) =>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={item.id || i}
                style={{
                  minHeight: '200px',
                  padding:'20px'
                }}
              >
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                >
                  {item.avatar &&
                    <Grid 
                      item
                      style={{
                        alignSelf:"flex-start"
                      }}
                    >
                      <Avatar
                        alt={item.avatar.alt}
                        src={item.avatar.src} 
                      />
                    </Grid>
                  }
                  <Grid item xs={12} sm={8}>
                    <div style={{maxWidth:'100%'}}>
                      {item.text &&
                        <>
                          <Typography
                            children={item.text.primary}
                          />
                          <Typography
                            children={item.text.secondary}
                            noWrap={true}
                            style={{whiteSpace: 'pre-line'}}
                          />
                        </>
                      }
                      {item.star_rating &&
                        <ListItemText
                          disableTypography={true}
                          primary={
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Icon
                                style={{
                                  display:"flex",
                                  alignItems:"center"
                                }}
                              >
                                <Star color={'primary'} />
                              </Icon>
                              {item.star_rating.number &&
                                <Grid item>
                                  <Typography
                                    color="textSecondary"
                                    component="div"
                                    variant="h6"
                                  >
                                    {item.star_rating.number}
                                  </Typography>
                                </Grid>
                              }
                              {item.star_rating.reviews &&
                                <Grid item>
                                  <Typography
                                    color="textSecondary"
                                    component="div"
                                    variant="body2"
                                  >
                                    {item.star_rating.reviews}
                                  </Typography>
                                </Grid>
                              }
                            </Grid>
                          } 
                        />
                      }
                    </div>
                  </Grid>
                  <Grid
                    item
                    style={{
                      alignSelf:"flex-start"
                    }}
                  >
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => handleRemoveFromComparison(item)}
                    >
                      <HighlightOff fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box
            alignSelf="center"
            p={1}
          >
            <Link
              href={`${compareLink}?agent_ids=${items.map((item) => item.agent_id).join(',')}`}
            >
              <Button
                size="large"
                color={'primary'}
                disableElevation
                variant={'contained'}
              >
                {"Compare"}
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Drawer>
  )
}

export const ComparisonToggleButton = ({item, items, handleAddToComparison, handleRemoveFromComparison}) => {
  const [color, setColor] = useState("primary")
  const [text, setText] = useState("Add to comparison")
  const [variant, setVariant] = useState("contained")

  useEffect( () => {
    const isCompared = items.some((comparedItem) => comparedItem.id === item.id)
    if (isCompared) {
      setColor("secondary")
      setText("Remove from comparison")
      setVariant("outlined")
    } else {
      setColor("primary")
      setText("Add to comparison")
      setVariant("contained")
    }
  }, [items])

  const handleClick = event => {
    const isCompared = items.some((comparedItem) => comparedItem.id === item.id)
    if (!isCompared) {
      handleAddToComparison(item)
    } else {
      handleRemoveFromComparison(item)
    }
  }

  return (
    <Button
      size="large"
      color={color}
      disableElevation
      onClick={handleClick}
      variant={variant}
    >
      {text}
    </Button>
  )
}
