import { createTheme } from '@mui/material/styles'

export const vendorableMuiTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#f32e74',
      },
      secondary: {
        main: '#767676',
      },
      success: {
        main: '#2d9691',
      },
    },
    typography: {
      fontFamily: [
        '"proxima-nova"',
        '"Helvetica Neue"',
        'Helvetica',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
      button: {
        textTransform: "unset",
      },
    },
  }
)
