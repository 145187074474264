import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sort-controls"
export default class extends Controller {
  static outlets = ["sort-submit"]

  sortColumn({ params: { field } }) {
    this.sortSubmitOutlet.sortColumn(field)
  }
}
