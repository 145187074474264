export default function radioButtonsWithBooleanAttributes (nodeList) {
  // the nth checked radio button sets the nth hidden input field to true within each container 'wrapper'
  // and sets the other hidden inputs to false
  if (nodeList.length === 0) return;

  nodeList.forEach( (wrapper) => {
    let radioInputs, hiddenInputs
    radioInputs = wrapper.querySelectorAll('input[type=radio]')
    hiddenInputs = wrapper.querySelectorAll('input[type=hidden]')
    hiddenInputs.forEach( (e, i) => {
      if (e.value === true) {
        radioInputs[i].checked = true
      }
    })
    radioInputs.forEach( (input, index) => {
      input.addEventListener('change', event => {
        if (input.checked) {
          hiddenInputs.forEach( (e) => {e.value = false})
          if (hiddenInputs[index] !== undefined) {
            hiddenInputs[index].value = true
          }
        }
      })
    })
  })
}
