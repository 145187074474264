export default function trixEditorToolbarCustomisation (editorList) {
  if (editorList.length === 0) return
  editorList.forEach( (editor) => {
    customise(editor)
  })
  document.addEventListener("cocoon:after-insert", (e) => {
    const insertedItem = e.target.previousElementSibling
    insertedItem.querySelectorAll("trix-editor").forEach( (editor) => {
      customise(editor)
    })
    jQuery(insertedItem).foundation()
  })
}
function customise(editor) {
  const toolbar = editor.previousElementSibling
  if (toolbar.tagName === "TRIX-TOOLBAR") {
    if (editor.dataset.attachWithChonky === "true") {
      const attachButton = toolbar.querySelector("[data-trix-action='attachFiles']")
      if (attachButton) {
        delete attachButton.dataset.trixAction
        attachButton.dataset.open = `chonky_modal_${editor.id}` //? or render the Browser in a modal with react or what?
        toolbar.insertAdjacentHTML("afterend", `
          <div id="chonky_modal_${editor.id}" class="large reveal" data-reveal data-animation-in="fade-in" data-animation-out="fade-out" data-multiple-opened="true">
            <h1 class="h4">Select files to attach</h1>
            <button class="close-button" data-close aria-label="Close modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
            <div
              class="chonky-browser"
              id="chonky_${editor.id}"
              data-controller="render-react-component"
              data-react-component="chonky-browser"
              data-trix-editor="true"
              data-source="${editor.dataset.source}"
              data-blob-url-template="${editor.dataset.blobUrlTemplate}"
              data-blob-representation-url-template="${editor.dataset.blobRepresentationUrlTemplate}"
              style="height: 440px;">
            </div>
          </div>
        `)
        document.addEventListener('trix-attachment-add', event => { $(`#chonky_modal_${editor.id}`).foundation('close') })
      }
    }
    if (editor.dataset.textOnly === "true") {
      editor.addEventListener("trix-file-accept", e => e.preventDefault())
      if (toolbar.querySelector("[data-trix-action='attachFiles']")) {
        toolbar.querySelector("[data-trix-action='attachFiles']").parentElement.remove()
      }
    }
  }
}
