export default function serviceFeeCalc (el) {
  if (!el) return
  const consumptionTaxRate = parseFloat(document.querySelector('[data-consumption-tax-rate]').dataset.consumptionTaxRate) || 0
  let upperPriceEstimate = parseInt(document.getElementById('bid_upper_price_estimate').value) || 0
  let commissionFixed = parseInt(document.getElementById('bid_commission_fixed').value) || 0
  let commissionPercentage = parseFloat(document.getElementById('bid_commission_percentage').value) || 0.0
  let commissionFirstTierPrice = parseInt(document.getElementById('bid_commission_first_tier_price').value) || 0
  let commissionFirstTierPercentage = parseInt(document.getElementById('bid_commission_first_tier_percentage').value) || 0.0
  let commissionFee = 0
  document.getElementById('bid_upper_price_estimate').addEventListener("change", () => {
    upperPriceEstimate = parseInt(document.getElementById('bid_upper_price_estimate').value)
    handleInputChange()
  })
  document.getElementById('bid_commission_fixed').addEventListener("change", () => {
    commissionFixed = parseInt(document.getElementById('bid_commission_fixed').value)
    handleInputChange()
  })
  document.getElementById('bid_commission_percentage').addEventListener("change", () => {
    commissionPercentage = parseFloat(document.getElementById('bid_commission_percentage').value)
    handleInputChange()
  })
  document.getElementById('bid_commission_first_tier_price').addEventListener("change", () => {
    commissionFirstTierPrice = parseFloat(document.getElementById('bid_commission_first_tier_price').value)
    handleInputChange()
  })
  document.getElementById('bid_commission_first_tier_percentage').addEventListener("change", () => {
    commissionFirstTierPercentage = parseFloat(document.getElementById('bid_commission_first_tier_percentage').value)
    handleInputChange()
  })
  const handleInputChange = () => {
    if ( commissionFixed > 0 ) {
      commissionFee = commissionFixed
    }
    else {
      if (commissionFirstTierPrice > 0 && commissionFirstTierPercentage > 0) {
        if (upperPriceEstimate <= commissionFirstTierPrice) {
          commissionFee = upperPriceEstimate * (commissionPercentage / 100)
        }
        else {
          const baseCommissionFee = commissionFirstTierPrice * (commissionPercentage / 100)
          const firstTierSum = upperPriceEstimate - commissionFirstTierPrice
          const firstTierCommissionFee = firstTierSum * (commissionFirstTierPercentage / 100)
          commissionFee = baseCommissionFee + firstTierCommissionFee
        }
      }
      else {
        commissionFee = upperPriceEstimate * (commissionPercentage / 100)
      }
    }
    const netServiceFee = commissionFee * 0.05
    const serviceFeeTax = netServiceFee * consumptionTaxRate
    const serviceFee = netServiceFee + serviceFeeTax
    document.querySelector('[data-upper-price-estimate]').innerHTML = upperPriceEstimate.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    document.querySelector('[data-commission-fee]').innerHTML = commissionFee.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    document.querySelector('[data-service-fee-tax]').innerHTML = serviceFeeTax.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    document.querySelector('[data-service-fee]').innerHTML = serviceFee.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  handleInputChange()
}
