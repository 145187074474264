import { Loader } from "@googlemaps/js-api-loader"

export default function addressInputComplete (nodeList) {
  if (nodeList.length === 0) return;
  const loader = new Loader({
    apiKey: "AIzaSyA6Pv_YDs9HxqESCCYmtYuyiZomxMCNypY",
    version: "weekly",
  })
  loader.load().then(async () => {
    const { Autocomplete } = await google.maps.importLibrary("places")
    nodeList.forEach( (input) => {
      new Autocomplete(input, {types: ['geocode']})
    })
  })
}
