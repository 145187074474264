import 'jquery'
import inputClearedOnClosedByFoundation from './input_cleared_on_closed_by_foundation'

export default function inputDuplicator (nodeList) {
  nodeList.forEach( (node) => {
    node.addEventListener('click', (e) => {
      const inputContainer = e.target.previousElementSibling.cloneNode(true)
      inputContainer.removeAttribute("style")
      enableButton(inputContainer)
      const input = inputContainer.querySelector('input')
      sanitizeInput(input)
      inputClearedOnClosedByFoundation([inputContainer])
      jQuery(e.target.parentElement.insertBefore(inputContainer, e.target)).foundation()
    })
  })
}

export function duplicateInput (el, with_container) {
  const inputContainer = el.parentNode.cloneNode(true)
  inputContainer.removeAttribute("style")
  enableButton(inputContainer)
  const input = inputContainer.querySelector('input')
  sanitizeInput(input)
  inputClearedOnClosedByFoundation([inputContainer])
  if (with_container) {
    jQuery(inputContainer).foundation()
    return inputContainer
  } else {
    return input
  }
}

function sanitizeInput (input) {
  input.value = ''
  input.removeAttribute("disabled")
  input.removeAttribute("readonly")
  const id = input.id.replace(/(\d+)/g, (a,n) => { return (+n+1) })
  const name = input.name.replace(/(\d+)/g, (a,n) => { return (+n+1) })
  input.id = id
  input.name = name
}

function enableButton (inputContainer) {
  const button = inputContainer.querySelector('button')
  if (button) {
    button.classList.remove('disabled')
    if (button.parentElement.classList.contains("input-group-button")) {
      button.parentElement.dataset.close = ""
    }
  }
}
