import React, { useMemo } from 'react';
import {
  useExpanded,
  useTable,
} from 'react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { prepareColumns } from './react_table'
import ControlsMenu from './controls_menu'

export default function ReactSubTable(props) {
  const columns = useMemo( () => {
    return prepareColumns(props.columns)
  }, [props.columns])
  const data = useMemo( () => {
    return props.data
  }, [props.data])
  const visibleColumnsNum = useMemo( () => {
    return props.visibleColumnsNum
  }, [props.colSpan])
  const {
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
  )
  return (
    <TableRow>
      <TableCell />
      <TableCell colSpan={visibleColumnsNum -1} style={{borderBottom:'none', padding:'unset'}}>
        <div>
          <Table>
            <TableBody>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()} className={row.original.class_name}>
                    {row.cells.map(cell => {
                      return(
                        <TableCell{...cell.getCellProps()} style={{fontWeight: 200}}>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </TableCell>
    </TableRow>
  )
}
