export default function colourPicker (nodeList) {
  if (nodeList.length === 0) return;
  nodeList.forEach( (container) => {
    const selections = container.querySelectorAll('[data-colour-hex]')
    selections.forEach( (selection) => {
      selection.addEventListener('click', (e) => {
        const input = container.querySelector('input')
        const label = container.querySelector('label')
        const hex = e.target.dataset.colourHex
        const selectionsDropdown = container.querySelector('[data-dropdown]')
        input.value = hex
        label.style.backgroundColor = hex
        jQuery(selectionsDropdown).foundation('close')
        e.preventDefault()
      })
    })
  })
}
