import CalHeatMap from 'cal-heatmap'

export default function agentProfilesHeatmap (el) {

  if (!el) return;

  let startDate = new Date
  startDate.setFullYear(startDate.getFullYear() - 1)
  let cal = new CalHeatMap()
  cal.init({
    start: startDate,
    maxDate: new Date(),
    itemSelector: "#heatmap",
    itemName: ["activity", "activities"],
    data: $('#heatmap').data('source'),
    legend: [1,3,5,10],
    legendColors: ["#f5f7dc","#db0d56"],
    range: 12,
    domain: "month",
    domainLabelFormat: "%b",
    subDomain: "day",
    cellSize: 7.5,
  })
}
