import Rails from '@rails/ujs'

let loadMore = function(el, url, button) {
  if (!el) return;
  if (!button) return;
  el.dataset.offset = 0
  let loader = function () {
    button.disabled = true
    el.insertAdjacentHTML('beforeend', `
      <div id="loadMoreLoading" class="cell text-center">
        <i class="fas fa-spin fa-2x fa-sync-alt"></i>
      </div>
    `)
    Rails.ajax({
      url: url,
      type: "get",
      dataType: 'json',
      data: `offset=${el.dataset.offset}&${el.dataset.params}`,
      success: function(result) {
        if ( result.records_loaded === 0 ) { button.remove() }
        el.dataset.offset = result.records_loaded+parseInt(el.dataset.offset, 10)
        document.getElementById('loadMoreLoading').remove()
        el.insertAdjacentHTML('beforeend', result.html)
        button.disabled = false 
      },
      error: function(result) {}
    })
  }
  loader()
  button.addEventListener('click', loader)
}
export default loadMore
