import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import {
  Close,
} from '@mui/icons-material'
import { SlideUpTransition } from './transitions'

export default function ContentDialog(props) {
  const [open, setOpen] = useState(false)
  const {content} = useMemo( () => {
    return props
  }, [props])
  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <div onClick={handleClick}>
        {props.children}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideUpTransition}
      >
        <DialogTitle
          disableTypography={true}
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dangerouslySetInnerHTML={{__html: content}} />
      </Dialog>
    </>
  )
}
