export default function cocoonConfirm () {
  document.addEventListener("cocoon:before-remove", (e) => {
    if (e.detail.querySelector('[data-cocoon-confirm]')) {
      let confirmation = confirm(e.detail.querySelector('[data-cocoon-confirm]').dataset.cocoonConfirm)
      if (confirmation === false) {
        e.preventDefault()
      }
    }
  })
}
