import SignaturePad from 'signature_pad'

export default function signaturePad (nodeList) {
  if (nodeList.length === 0) return;
  nodeList.forEach( (input) => {
    const canvas = document.createElement('canvas')
    const signaturePad = new SignaturePad(canvas)
    signaturePad.addEventListener("afterUpdateStroke", () => {
      input.value = signaturePad.toDataURL()
    })
    const clearSignatureButton = document.createElement('span')
    clearSignatureButton.className = 'clear button'
    clearSignatureButton.innerHTML = 'Clear'
    clearSignatureButton.addEventListener('click', () => {
      signaturePad.clear()
    })
    input.insertAdjacentElement('beforebegin', canvas)
    input.insertAdjacentElement('beforebegin', clearSignatureButton)
  })
}
