export default function inputStepper (nodeList, options=undefined) {

  let defaults = {
    selectorButtonIncrease: '[data-input-stepper-increase]',
    selectorButtonDecrease: '[data-input-stepper-decrease]',
    dataAttributeIncrease: 'inputStepperIncrease',
    dataAttributeDecrease: 'inputStepperDecrease',
    classNameDisabled: 'is-disabled',
    selectorInput: 'input',
    initialValue: undefined,
    min: 0,
    max: Infinity
  }

  options = {
    ...defaults,
    ...options
  }

  function init(el) {
    let input = el.querySelector(options.selectorInput),
        decreaseButton = el.querySelector(options.selectorButtonDecrease),
        increaseButton = el.querySelector(options.selectorButtonIncrease),
        min = input.min || options.min,
        max = input.max || options.max,
        initialValue = getValue() || options.initialValue || min

    setValue(initialValue, true)
    checkState(initialValue)
    input.setAttribute('pattern', '[0-9]*')
    input.addEventListener('keydown', onKeyDown)
    input.addEventListener('change', onChange)
    increaseButton.addEventListener('click', (e) => {
      e.preventDefault()
      increase( increaseButton.dataset[options.dataAttributeIncrease] || 1 )
    })
    decreaseButton.addEventListener('click', (e) => {
      e.preventDefault()
      decrease( decreaseButton.dataset[options.dataAttributeDecrease] || 1 )
    })

    function getValue() {
      return input.value
    }

    function setValue(amount, isSilent) {
      if (parseInt(input.value, 10) === parseInt(amount, 10) ){
        return
      }
      else {
        _setValue(amount, isSilent)
      }
    }
    
    function _setValue(amount, isSilent) {
      if (amount !== '') {
        amount = parseInt(amount, 10)
      } else {
        amount = initialValue
      }

      if (amount >= max) {
        amount = parseInt(max, 10)
      }

      if (amount <= min) {
        amount = parseInt(min, 10)
      }

      if (!isSilent) {
        input.value = amount
        input.dispatchEvent(new Event("change"))
      } else {
        input.value = amount
      }

      checkState(amount)
    }

    function disableButton(button) {
      button.classList.add(options.classNameDisabled)
      button.disabled = true
    }

    function enableButton(button) {
      button.classList.remove(options.classNameDisabled)
      button.disabled = false
    }

    function checkState(amount) {
      if (amount <= min) {
        disableButton(decreaseButton)
      } else {
        enableButton(decreaseButton)
      }

      if (amount >= max) {
        disableButton(increaseButton)
      } else {
        enableButton(increaseButton)
      }
    }
    
    function onChange(e) {
      if (getValue() === '') {
        setValue(initialValue)
      } else {
        _setValue(getValue(), true)
      }
    }

    function onKeyDown(e) {
      let isEnterKey = e.keyCode == 13

      if (isEnterKey) {
        setValue(getValue())
        return e.preventDefault()
      }

      let isSomeKeys = [46, 8, 9, 27, 13, 110].includes(e.keyCode)
      let isMeta = e.metaKey || e.altKey || e.ctrlKey; 
      let isHomeEndLeftRight = (e.keyCode >= 35 && e.keyCode <= 39)
      let isUpKey = e.keyCode == 38
      let isDownKey = e.keyCode == 40

      if (isUpKey) increase( (e.shiftKey ? 10 : 1) )
      if (isDownKey) decrease( (e.shiftKey ? 10 : 1) )
      if (isSomeKeys || isMeta || isHomeEndLeftRight) return
      if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        setValue(getValue())
      }
      if ((e.shiftKey || ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105))) ) {
        e.preventDefault()
      }
    }

    function increase(amount) {
      if (increaseButton.classList.contains(options.classNameDisabled)) return;
      let newAmount = parseInt(getValue(), 10) + parseInt(amount || 1, 10)
      setValue(newAmount)
    }

    function decrease(amount) {
      if (decreaseButton.classList.contains(options.classNameDisabled)) return;
      let newAmount = parseInt(getValue(), 10) - parseInt(amount || 1, 10)
      setValue(newAmount)
    }
  }
  nodeList.forEach(init)
}
