import 'jquery'
import consumer from "./consumer"

consumer.subscriptions.create("UserNotificationsChannel", {
  // Called once when the subscription is created.
  initialized() {
    this.update = this.update.bind(this)
  },

  connected() {
    // Called when the subscription is ready for use on the server
    this.install()
    this.update()
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    this.uninstall()
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.unread_size !== undefined) {
      let bell = document.getElementById('notificationsBell')
      if (data.unread_size > 0) {
        bell.innerHTML = `<span class="counter warning has-tip tip-right radius"
                          data-tooltip aria-haspopup="true"
                          data-click-open="false"
                          title="You have unread notifications">
                            ${(data.unread_size > 9 ? '9+' : data.unread_size )}
                          </span>`
        jQuery(bell).foundation()
      }
      else {
        bell.innerHTML = ""
      }
    }
    if (data.html !== undefined) {
      this.update()
      if (!!document.querySelector("[data-channel='user_notifications']")){
        const template = document.createElement("div")
        template.innerHTML = data.html
        document.querySelector("[data-channel='user_notifications']").prepend(template.firstElementChild)
        this.perform("received", {id: data.id});
      }
    }
  },

  install() {
    document.addEventListener("turbo:load", this.update)
  },

  uninstall() {
    document.removeEventListener("turbo:load", this.update)
  },

  update() {
    this.perform("unread_size")
  }
})
