import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cleared-submit"
export default class extends Controller {
  static outlets = ["pagination-submit"]
  static targets = ["clearedSubmit"]

  connect() {
    this.clearedSubmitTarget.addEventListener("input", this.submitForm.bind(this))
  }

  submitForm() {
    if (this.clearedSubmitTarget.value.trim() === "") {
      if (this.hasPaginationSubmitOutlet) {
        this.paginationSubmitOutlet.setToFirstPage()
      }
      this.element.requestSubmit()
    }
  }
}
