export default function directUpload (forms) {
  forms.forEach( (form) => {
    form.querySelectorAll("input[type=file][data-direct-upload-url]").forEach( input => {
      const cache = form.querySelector(`input[type='hidden'][name='${input.name}'][filenames]`)
      input.insertAdjacentHTML("afterend", `<div id="${input.id}_file_list" class="direct-uploads-file-list"></div>`)
      const fileList = document.getElementById(`${input.id}_file_list`)
      if (cache) {
        cache.attributes.filenames.value.split(',').forEach(filename => {
          fileList.insertAdjacentHTML("afterbegin", `
            <div class="direct-upload direct-upload--complete">
              <div class="direct-upload__progress" style="width: 100%"></div>
              <span class="direct-upload__filename">${filename}</span>
            </div>
          `)
        })
      }
      input.addEventListener('change', function (event) {
        if (!this.files.length) {
        } else {
          if (cache) { cache.remove() }
          fileList.innerHTML = ""
          Array.from(input.files).forEach(file => {
            fileList.insertAdjacentHTML("afterbegin", `
              <div class="direct-upload direct-upload--pending">
                <div class="direct-upload__progress" style="width: 0%"></div>
                <span class="direct-upload__filename">${file.name}</span>
              </div>
            `)
          })  
        }
      })
    })

    form.addEventListener("direct-uploads:start", event => {
      form.querySelectorAll(".direct-uploads-file-list").forEach( list => {
        list.remove()
      })
    })

    form.addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event
      const { id, file } = detail
      target.insertAdjacentHTML("beforebegin", `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename"></span>
        </div>
      `)
      target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
    })

    form.addEventListener("direct-upload:start", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.remove("direct-upload--pending")
    })

    form.addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail
      const progressElement = document.getElementById(`direct-upload-progress-${id}`)
      progressElement.style.width = `${progress}%`
    })

    form.addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--error")
      element.setAttribute("title", error)
    })

    form.addEventListener("direct-upload:end", event => {
      const { id } = event.detail
      const element = document.getElementById(`direct-upload-${id}`)
      element.classList.add("direct-upload--complete")
    })
  })
}
