import 'jquery'
import 'foundation-datepicker'

let datePicker = function (nodeList) {
  if (nodeList.length === 0) return;
  nodeList.forEach( (e) => {
    jQuery(e).fdatepicker({
      closeButton: false,
    })
  })
}
export default datePicker
