import 'jquery'
import Rails from '@rails/ujs'

function photoSlide (src, caption) {
  let listItem = document.createElement('li')
  listItem.className = "orbit-slide"
  listItem.innerHTML = `<div class="grid-y align-center-middle" style="height: 420px">
                          <div class="cell text-center">
                            <figure class="orbit-figure">
                              <img class="orbit-image" src="${src}" alt=''>
                              <figcaption class="orbit-caption">${caption}</figcaption>
                            </figure>
                          </div>
                        </div>`
  return listItem
}

function markupSlide (html) {
  let listItem = document.createElement('li')
  listItem.className = "orbit-slide"
  listItem.innerHTML = html
  return listItem
}

export function orbitSlides(dataSource, orbitWrapper=dataSource, targetList=[]) {
  if (!dataSource) return;

  Rails.ajax({
    url: dataSource.dataset.source,
    type: "get",
    dataType: 'json',
    data: '',
    success(result) {
      result.forEach( (slide) => {
        if (!!slide.html) {
          let newSlide = orbitWrapper.querySelector('.orbit-container').appendChild(markupSlide(slide.html))
        }
        if (!!slide.src) {
          orbitWrapper.querySelector('.orbit-container').appendChild(photoSlide(slide.src, slide.caption))
        }
      })
      if (!!orbitWrapper.dataset.reveal) {
        jQuery(orbitWrapper).on('open.zf.reveal', () => {
            Foundation.reInit(jQuery(orbitWrapper.querySelector('.orbit')))
          }
        )
        targetList.forEach( (thumb) => {
          thumb.addEventListener('click', (event) => {
            jQuery(orbitWrapper).foundation('open')
            let index = Array.prototype.indexOf.call(targetList, event.target)
            orbitWrapper.querySelectorAll("[data-slide]").forEach( (slide) => {
              if (slide === orbitWrapper.querySelector(`[data-slide="${index}"]`)) {
                slide.classList.add("is-active")
                slide.removeAttribute("style")
              }
              else {
                slide.classList.remove("is-active")
                slide.style = "display: none;"
              }
            })
          })
        })
      }
      else if (!!orbitWrapper.closest('.tabs-panel') && 
              !orbitWrapper.closest('.tabs-panel').classList.contains('is-active')) {
        let targetId = orbitWrapper.closest('.tabs-panel').id
        jQuery(document.querySelector(`[data-tabs-target=${targetId}]`).parentElement.parentElement).on('change.zf.tabs', () => {
          if (orbitWrapper.closest('.tabs-panel').classList.contains('is-active')) {
            Foundation.reInit(jQuery(orbitWrapper.querySelector('.orbit')))
          }
        })
      }
      else {
        Foundation.reInit(jQuery(orbitWrapper.querySelector('.orbit')))
      }
    },
    error(result) {}
  })
}
