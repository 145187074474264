import React, { useEffect, useRef, useState } from 'react';
import useOnScreen from "../react_hooks/use_on_screen"
import Rails from '@rails/ujs'
import consumer from "../../channels/consumer"
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Portal,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  KeyboardArrowLeft,
} from '@mui/icons-material'
import {
  StyledEngineProvider,
  ThemeProvider,
  useTheme
} from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { SlideUpTransition } from './transitions'
import { vendorableMuiTheme } from "./themes"

export default function MessageRw(props) {
  const wrapper = props.wrapper
  const [channel, setChannel] = useState()
  const [conversations, setConversations] = useState([])
  const [conversationsParams, setConversationsParams] = useState({})
  const [conversationsLoading, setConversationsLoading] = useState(false)
  const [conversationsIndex, setConversationsIndex] = useState()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [form, setForm] = useState('')
  const [messageLastLoadedId, setMessageLastLoadedId] = useState(0)
  const [messageLoadingBottom, setMessageLoadingBottom] = useState(false)
  const [messageLoadingTop, setMessageLoadingTop] = useState(false)
  const [messageOffset, setMessageOffset] = useState(0)
  const [messageReferenceId, setMessageReferenceId] = useState(0)
  const [thread, setThread] = useState([])
  const [threadURL, setThreadURL] = useState('') 
  const [threadParams, setThreadParams] = useState({})
  const [threadLoading, setThreadLoading] = useState(false)
  const [threadHeader, setThreadHeader] = useState('')
  const [threadContact, setThreadContact] = useState('')
  const [threadReadyToLoadMore, setThreadReadyToLoadMore] = useState(false)
  const conversationsRef = useRef(null)
  const formRef = useRef(null)
  const dialogRef = useRef(null)
  const threadListRef = useRef(null)
  const threadLoadMoreRef = useRef(null)
  const threadScrollRef = useRef(null)

  const onLoadMoreThread = useOnScreen(threadLoadMoreRef, {root: threadListRef.current})

  const theme = useTheme()
  const displayFullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect( () => {
    getConversations()
  }, [conversationsParams])

  useEffect( () => {
    if (threadURL.length > 0) {
      getThread(threadURL)
    }
  }, [threadURL])

  useEffect( () => {
    if (threadURL.length > 0 && Object.keys(threadParams).length > 0 ) {
      loadMoreThread(threadURL)
    }
  }, [threadParams])

  useEffect( () => {
    if (onLoadMoreThread && conversationsIndex !== undefined && threadReadyToLoadMore) {
      handleLoadMoreThread()
    }
  }, [onLoadMoreThread, threadReadyToLoadMore])

  useEffect( () => {
    if (threadScrollRef.current) {
      threadScrollRef.current.focus()
      threadScrollRef.current.scrollIntoView({
        behaviour: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [threadScrollRef.current, messageLastLoadedId])

  useEffect( () => {
    if (formRef.current && form.length > 0) {
      const f = formRef.current.children[0]
      const button = f.querySelector('button[type=submit]')  
      const textarea = f.querySelector('trix-editor')
      textarea.focus()
      if (textarea.value === undefined || textarea.value.trim() === '') {
        button.disabled = true
      }
      textarea.addEventListener('keyup', (e) => {
        if (textarea.value.trim() === '' || textarea.editor.getDocument().toString().trim() === '') {
          button.disabled = true
        }
        else {
          button.disabled = false
          if (e.keyCode == 13 && (e.metaKey || e.ctrlKey)) {
            Rails.fire(f, 'submit')
          }
        }
      })
      textarea.addEventListener('trix-change', (e) => {
        if (textarea.value.trim() === '' || textarea.editor.getDocument().toString().trim() === '') {
          button.disabled = true
        }
        else {
          button.disabled = false
        }
      })

      f.addEventListener("submit", (e) => {
        textarea.editor.moveCursorInDirection('forward')
        setMessageLoadingBottom(true)
        e.preventDefault()
        if (threadScrollRef.current) {
          threadScrollRef.current.scrollIntoView({
            behaviour: "smooth",
            block: "nearest",
            inline: "nearest",
          })
        }
      })

      f.addEventListener("ajax:success", () => {
        textarea.editor.loadDocument()
        f.reset()
        button.disabled = true
      })

      f.addEventListener("ajax:error", () => {
        setMessageLoadingBottom(false)
        button.disabled = false
        setThread(prev => [...prev, {
          avatar: "",
          date: "",
          html: "Your message could not be sent. Please try again.",
          name: "Error message",
        }])
      })
    }
  }, [form])

  useEffect( () => {
    if (displayFullScreen) {
      handleDialogClose()
    }
  }, [displayFullScreen])

  const getConversations = async () => {
    const isLoading = setTimeout(() => {
      setConversationsLoading(true)
    }, 500)
    Rails.ajax({
      url: wrapper.dataset.source,
      type: "get",
      dataType: 'json',
      data: Object.keys(conversationsParams).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(conversationsParams[key])}`
      }).join('&'),
      success(result) {
        setConversations(
          result.conversations.map(c => (
            {
              avatar: c.avatar,
              contact: c.contact,
              form: c.form,
              messagable_id: c.messagable_id,
              messagable_type: c.messagable_type,
              name: c.name,
              role: c.role,
              snippet: c.snippet,
              url: c.url,
            }
          ))
        )
        clearTimeout(isLoading)
        setConversationsLoading(false)
      },
      error(result) {
        setConversations([])
        clearTimeout(isLoading)
        setConversationsLoading(false)
      }
    })
  }

  const getThread = async (url) => {
    const isLoading = setTimeout(() => {
      setThreadLoading(true)
    }, 500)
    setMessageLoadingBottom(true)
    Rails.ajax({
      url: url,
      type: "get",
      dataType: 'json',
      data: Object.keys(threadParams).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(threadParams[key])}`
      }).join('&'),
      success(result) {
        setThreadLoading(false)
        setMessageLoadingBottom(false)
        clearTimeout(isLoading)
        if (result.thread.length > 0) {
          setMessageLastLoadedId(result.thread[result.thread.length -1].id)
          setMessageReferenceId(result.thread[result.thread.length -1].id)
          setMessageOffset(messageOffset+result.thread.length)
          setThread(
            result.thread.map(m => (
              {
                avatar: m.avatar,
                date: m.date,
                html: m.html,
                id: m.id,
                name: m.name,
                snippet: m.snippet,
              }
            ))
          )
          setThreadReadyToLoadMore(true)
        }
        setForm(conversations[conversationsIndex].form)
      },
      error(result) {
        setThreadLoading(false)
        setMessageLoadingBottom(false)
        setThread([])
        clearTimeout(isLoading)
      }
    })
  }

  const loadMoreThread = async (url) => {
    setThreadReadyToLoadMore(false)
    setMessageLoadingTop(true)
    Rails.ajax({
      url: url,
      type: "get",
      dataType: 'json',
      data: Object.keys(threadParams).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(threadParams[key])}`
      }).join('&'),
      success(result) {
        setMessageLoadingTop(false)
        if (result.thread.length > 0) {
          const moreThread = result.thread.map(m => (
            {
              avatar: m.avatar,
              date: m.date,
              html: m.html,
              id: m.id,
              name: m.name,
              snippet: m.snippet,
            }
          ))
          setMessageLastLoadedId(result.thread[result.thread.length -1].id)
          setMessageOffset(messageOffset+result.thread.length)
          setThread([...moreThread, ...thread])
          setThreadReadyToLoadMore(true)
        }
      },
      error(result) {
        setMessageLoadingTop(false)
        setThread([])
      }
    })
  }

  const handleLoadMoreThread = () => {
    const newParams = {
      offset: messageOffset,
      message_id: messageReferenceId,
    }
    const mergedParams = {...threadParams, ...newParams}
    setThreadParams(mergedParams)
  }

  const handleConversationClick = (event, index) => {
    if (channel !== undefined) {
      setChannel(
        channel.unsubscribe()
      )
    }
    setThread([])
    setThreadParams({})
    setMessageLastLoadedId(0)
    setMessageOffset(0)
    setMessageReferenceId(0)
    setThreadReadyToLoadMore(false)
    setConversationsIndex(index)
    setThreadURL(conversations[index].url)
    setThreadHeader(conversations[index].name)
    setThreadContact(conversations[index].contact)
    if (displayFullScreen) {
      setDialogOpen(true)
    }
    setChannel(
      consumer.subscriptions.create({
        channel: 'MessagesChannel',
        messagable_type: conversations[index].messagable_type,
        messagable_id: conversations[index].messagable_id,
      }, {
        initialized() {
        },
        connected() {
        },
        disconnected() {
        },
        received(data) {
          if (data !== undefined) {
            setMessageLoadingBottom(false)
            setThread(prev => [...prev, data.message])
            setConversations( (prev) => {
              prev[index].snippet = data.message.snippet
              return prev
            })
            setMessageLastLoadedId(data.message.id)
          }
        },
      })
    )
  }

  const handleDialogClose = () => {
    if (channel !== undefined) {
      setChannel(
        channel.unsubscribe()
      )
    }
    setThread([])
    setConversationsIndex()
    setThreadURL('')
    setThreadHeader('')
    setThreadContact('')
    setForm('')
    setDialogOpen(false)
    if (conversationsRef.current) {
      conversationsRef.current.scrollIntoView()
    }
  }

  const cache = createCache({
    key: "css",
    prepend: false,
    container: document.head,
    speedy: true,
  })

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={vendorableMuiTheme} >
          <CacheProvider value={cache}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Typography
                  ref={conversationsRef}
                  classes={{root: "message-rw-conversations-title"}}
                  color="textPrimary"
                  component="div"
                  variant="body1"
                >
                  {"Conversations"}
                </Typography>
                <List
                  className="message-rw-conversations-list"
                >
                  <Typography
                    color="textSecondary"
                    component="li"
                    variant="body2"
                  >
                    Channels
                  </Typography>
                  <Divider component="li"/>
                  {conversationsLoading === true &&
                    <LinearProgress variant="indeterminate"/>
                  }
                  {conversations.map( (c, i) =>
                    <ListItem
                      button
                      className="message-rw-conversations-list-item"
                      component="li"
                      disabled={conversationsIndex === i}
                      divider={true}
                      key={`${c.messagable_type}_${c.messagable_id}`}
                      onClick={(event) => handleConversationClick(event, i)}
                      selected={conversationsIndex === i}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={c.name}
                          src={c.avatar} 
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={c.name}
                        primaryTypographyProps={{
                          noWrap: true,
                        }}
                        secondary={c.snippet}
                        secondaryTypographyProps={{
                          noWrap: true,
                        }}
                      />
                      <ListItemSecondaryAction>
                        <Typography
                          children={c.role}
                          align="right"
                          variant="overline"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {conversations.length === 0 &&
                    <ListItem>
                      <ListItemText
                        primary={"You do not have any conversations"}
                      />
                    </ListItem>
                  }
                </List>
              </Grid>
              <Grid item xs={12} md={8}>
                <Portal
                  container={dialogRef.current}
                  disablePortal={!displayFullScreen}
                >
                  {conversationsIndex !== undefined &&
                    <Typography
                      classes={{root: "message-rw-thread-header"}}
                      color="textPrimary"
                      component="div"
                      variant="body1"
                    >
                      {threadHeader}
                      {!displayFullScreen &&
                        <IconButton
                          aria-label="download thread"
                          className="message-rw-thread-pdf-button"
                          href={`${threadURL}.pdf`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Icon className="far fa-file-pdf" color="primary"/>
                        </IconButton>
                      }
                    </Typography>
                  }
                  <div
                    className="message-rw-thread-list"
                  >
                    {thread.length > 0 &&
                      <List
                        ref={threadListRef}
                      >
                        <ListItem
                          component="li"
                          key="threadContact"
                        >
                          <div
                            dangerouslySetInnerHTML={{__html: threadContact}}
                          />
                        </ListItem>
                        {threadLoading === true &&
                          <LinearProgress variant="indeterminate"/>
                        }
                        {messageLoadingTop === true &&
                          <ListItem>
                            <CircularProgress/>
                          </ListItem>
                        }
                        {thread.length > 0 &&
                          <ListItem ref={threadLoadMoreRef} />
                        }
                        {thread.map( (m, i) =>
                          <ListItem
                            component="li"
                            key={`message_${m.id}`}
                            ref={messageLastLoadedId === m.id ? threadScrollRef : undefined}
                            tabIndex={messageLastLoadedId === m.id ? -1 : null}
                          >
                            <Card variant="outlined">
                              <CardHeader
                                avatar={
                                  <Avatar
                                    alt={m.name}
                                    src={m.avatar}
                                  />
                                }
                                title={m.name}
                                subheader={m.date}
                              />
                              <CardContent dangerouslySetInnerHTML={{__html: m.html}} />
                            </Card>
                          </ListItem>
                        )}
                        {messageLoadingBottom === true &&
                          <ListItem ref={threadScrollRef}>
                            <CircularProgress/>
                          </ListItem>
                        }
                      </List>
                    }
                  </div>
                  <div
                    dangerouslySetInnerHTML={{__html: form}}
                    ref={formRef}
                  />
                </Portal>
              </Grid>
            </Grid>
            <Dialog
              fullScreen
              open={dialogOpen}
              onClose={handleDialogClose}
              TransitionComponent={SlideUpTransition}
            >
              <DialogTitle>
                <IconButton aria-label="close" onClick={handleDialogClose}>
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  aria-label="download thread"
                  className="message-rw-thread-pdf-button"
                  href={`${threadURL}.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon className="far fa-file-pdf" color="primary"/>
                </IconButton>
              </DialogTitle>
              <DialogContent ref={dialogRef} />
            </Dialog>
          </CacheProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}
