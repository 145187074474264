import { Loader } from "@googlemaps/js-api-loader"
let addressAutocomplete = function (el) {
  if (!el) return;

  const loader = new Loader({
    apiKey: "AIzaSyA6Pv_YDs9HxqESCCYmtYuyiZomxMCNypY",
    version: "weekly",
  })

  let id = el.id
  let componentForm

  let auComponentForm = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name',
    country: 'long_name'
  }

  let ukComponentForm = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    postal_town: 'long_name',
    postal_code_prefix: 'short_name',
    postal_code: 'short_name',
    country: 'long_name'
  }

  let nzComponentForm = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    sublocality: 'long_name',
    locality: 'long_name',
    postal_code_prefix: 'short_name',
    postal_code: 'short_name',
    country: 'long_name'
  }

  let caComponentForm = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code_prefix: 'short_name',
    postal_code: 'short_name',
    country: 'long_name'
  }

  let usComponentForm = caComponentForm;

  loader.load().then(async () => {
    const { Autocomplete } = await google.maps.importLibrary("places")
    const autocomplete = new Autocomplete((document.getElementById(id)), {
      types: ['geocode']
    })
    
    autocomplete.addListener('place_changed', function() {
      const place = autocomplete.getPlace()
      const address = document.getElementById(id).value
      const address_components = place.address_components

      for (let i = 0; i < address_components.length; i++) {
        // get country field and choose forms
        const addressType = address_components[i].types[0]

        if (addressType == 'country') {
          const country = address_components[i].long_name

          document.getElementById('country').value = country
          document.getElementById('country').dispatchEvent(new Event('change', {bubbles: true}))

          if (country == 'Australia') {
            componentForm = auComponentForm
          } else if (country == 'United Kingdom') {
            componentForm = ukComponentForm
          } else if (country == 'New Zealand') {
            componentForm = nzComponentForm
          } else if (country == 'Canada') {
            componentForm = caComponentForm;
          } else if (country == 'United States') {
            componentForm = usComponentForm
          }
        }
      }

      // clean fields
      for (let component in componentForm) {
        if (component == 'postal_code_prefix') {
          document.getElementById('postal_code').value = ''
          document.getElementById('postal_code').disabled = false
          continue
        }

        document.getElementById(component).value = ''
        document.getElementById(component).disabled = false
      }

      // render each address field
      for (let i = 0; i < address_components.length; i++) {
        const addressTypes = address_components[i].types

        // some geocode result types has multiple values
        // i.e if types is [sublocality, political, sublocality_level_1]
        // we pick sublocality which is used in our form
        for (let j = 0; j < addressTypes.length; j++) {
          const addressType = addressTypes[j]

          if (componentForm[addressType]) {
            const val = address_components[i][componentForm[addressType]]
            if (addressType == 'postal_code_prefix') {
              document.getElementById('postal_code').value = val
            } else {
              document.getElementById(addressType).value = val
            }
          }
        }
      }
    })
  })

  let addressFields = document.getElementById('addressFields')

  el.addEventListener('keydown', event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      if (!!addressFields && addressFields.style.display === "none") {
        jQuery(addressFields).foundation('toggle')
        document.getElementById('showAddressFields').checked = true
      }
    }
  })

  el.addEventListener('change', event => {
    if (!!addressFields && addressFields.style.display === "none") {
      jQuery(addressFields).foundation('toggle')
      document.getElementById('showAddressFields').checked = true
    } 
  })

}
export default addressAutocomplete
