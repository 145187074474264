import React, { useEffect, useRef, useState } from 'react';
import Rails from '@rails/ujs'
import { Loader } from "@googlemaps/js-api-loader"
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Pagination,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material'
import {
  Star,
  Search,
} from '@mui/icons-material'
import {
  StyledEngineProvider,
  ThemeProvider,
  useTheme
} from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import ControlsMenu from './controls_menu'
import { ComparisonBar, ComparisonToggleButton } from './comparison_bar'
import { duplicateInput } from '../app_components/input_duplicator'
import { vendorableMuiTheme } from "./themes"

export default function SearchAndDisplay(props) {
  const wrapper = props.wrapper
  const addressAutocomplete = wrapper.dataset.addressAutocomplete
  const searchPlaceholder = wrapper.dataset.searchPlaceholder
  const compareLink = wrapper.dataset.compareLink
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState(0)
  const [took, setTook] = useState('')
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(parseInt(wrapper.dataset.defaultPageSize, 10) || 10)
  const [pageCount, setPageCount] = useState(0)
  const [params, setParams] = useState({})
  const [searchIsFocused, setSearchIsFocused] = useState(false)
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)
  const [showComparisonBar, setShowComparisonBar] = useState(false)
  const [comparedItems, setComparedItems] = useState([])
  const [canAddEmailToForm, setCanAddEmailToForm] = useState(false)
  const [emailFieldsContainer, setEmailFieldsContainer] = useState(undefined)
  const [emailAddedToFormItems, setEmailAddedToFormItems] = useState([])
  const [emailFieldsChanged, setEmailFieldsChanged] = useState(0)
  const searchRef = useRef(null)
  const drawNumRef = useRef(0)
  const theme = useTheme()
  const agentIds = wrapper.dataset.agentIds
  useEffect( () => {
    const isLoading = setTimeout(() => {
      setLoading(true)
    }, 500)
    Rails.ajax({
      url: wrapper.dataset.source,
      type: "get",
      dataType: 'json',
      data: Object.keys(params).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      }).join('&'),
      success(result) {
        setData(result.data)
        setTotalData(result.totalData)
        setTook(result.took)
        setPageCount(result.pageCount)
        setShowComparisonBar(result.showComparisonBar)
        setCanAddEmailToForm(result.canAddEmailToForm)
        clearTimeout(isLoading)
        setLoading(false)
      },
      error(result) {
        setData([])
        clearTimeout(isLoading)
        setLoading(false)
      }
    })
  }, [params] )

  useEffect( () => {
    let newParams = {}
    const drawNum = ++drawNumRef.current
    if (drawNum === drawNumRef.current && drawNum > 1) {
      newParams = {
        ...newParams, 
        ...{
          pageIndex: pageIndex,
          pageSize: pageSize,
        }
      }
      newParams = { ...newParams, ...{query: query} }
    }
    if (agentIds !== undefined && typeof agentIds === "string") {
      newParams = {
        ...newParams,
        ...{agent_ids: agentIds.split(",").map(str => parseInt(str.trim())).filter(num => !isNaN(num))}
      }
    }
    setParams({...params, ...newParams})
  }, [pageIndex, pageSize, query, agentIds] )

  useEffect( () => {
    if (addressAutocomplete) {
      const loader = new Loader({
        apiKey: "AIzaSyA6Pv_YDs9HxqESCCYmtYuyiZomxMCNypY",
        version: "weekly",
      })
      loader.load().then(async () => {
        const { Autocomplete } = await google.maps.importLibrary("places")
        const autocomplete = new Autocomplete(searchRef.current, {types: ['(regions)']})
        autocomplete.addListener('place_changed', handleSearchSubmit)
      })
    }
  }, [])

  useEffect( () => {
    setEmailFieldsContainer(document.getElementById(wrapper.dataset.emailFieldsId))
    if (emailFieldsContainer) {
      const handleInput = event => {
        const { target } = event
        if (target.matches('input[type="text"]')) {
          setEmailFieldsChanged(prev => prev + 1)
        }
      }
      emailFieldsContainer.addEventListener('input', handleInput)
      return () => {
        emailFieldsContainer.removeEventListener('input', handleInput)
      }
    }
  }, [canAddEmailToForm])

  useEffect( () => {
    if (canAddEmailToForm) {
      setEmailFieldsChanged(prev => prev + 1)
    }
  }, [canAddEmailToForm])

  useEffect( () => {
    if (canAddEmailToForm) {
      const emailFields = Array.from(emailFieldsContainer.querySelectorAll('input[type="text"]'))
      const emails = emailFields.map(field => field.value).filter(email => email !== undefined && email !== '')
      data.forEach( (item) => {
        if (
          emails.includes(item.email) &&
          !emailAddedToFormItems.some((addedItem) => addedItem.email === item.email)
        ) {
          addEmailToForm(item)
        }
        else if (
          !emails.includes(item.email) &&
          emailAddedToFormItems.some((addedItem) => addedItem.email === item.email)
        ) {
          removeEmailFromForm(item)
        }
      })
    }
  }, [data, emailFieldsChanged])

  const handleSearchFocus = () => {
    setSearchIsFocused(true)
  }

  const handleSearchBlur = () => {
    setSearchIsFocused(false)
  }

  const handleSearchSubmit = event => {
    if (event) {
      event.preventDefault()
    }
    if (searchRef.current.value.trim() === "") {
      return
    }
    setQuery(searchRef.current.value.trim())
    setPageIndex(0)
  }

  const handlePageChange = (event, page) => {
    setPageIndex(page-1)
  }

  const handleSearchChange = () => {
    if (searchRef.current === null || searchRef.current.value.trim() === "") {
      setSearchButtonDisabled(true)
      setQuery(searchRef.current.value.trim())
      setPageIndex(0)
    } else {
      setSearchButtonDisabled(false)
    }
  }

  const addToComparison = (item) => {
    const isCompared = comparedItems.some((comparedItem) => comparedItem.id === item.id)
    if (!isCompared) {
      setComparedItems(
        prev => [...prev, item]
      )
    }
  }

  const removeFromComparison = (item) => {
    const existingItem = comparedItems.find((comparedItem) => comparedItem.id === item.id)
    setComparedItems(
      prev => prev.filter(f => f !== existingItem)
    )
  }

  const addEmailToForm = (item) => {
    const isAdded = emailAddedToFormItems.some((addedItem) => addedItem.email === item.email)
    if (!isAdded) {
      setEmailAddedToFormItems(
        prev => [...prev, item]
      )
      const emailFields = Array.from(emailFieldsContainer.querySelectorAll('input[type="text"]'))
      const emails = emailFields.map(field => field.value).filter(email => email !== undefined && email !== '')
      if (!emails.includes(item.email)) {
        const targetInput = emailFieldsContainer.querySelector('input')
        const inputContainer = duplicateInput(targetInput, true)
        inputContainer.querySelector('input').value = item.email
        inputContainer.querySelector('input').setAttribute('value', item.email)
        emailFieldsContainer.insertBefore(inputContainer, emailFieldsContainer.firstChild)
      }
    }
  }

  const removeEmailFromForm = (item) => {
    const indexToRemove = emailAddedToFormItems.findIndex((addedItem) => addedItem.email === item.email)
    if (indexToRemove >= 0) {
      const newItems = [...emailAddedToFormItems]
      newItems.splice(indexToRemove, 1)
      setEmailAddedToFormItems(newItems)
    }
    const emailFields = Array.from(emailFieldsContainer.querySelectorAll('input[type="text"]'))
    emailFields.forEach( (field) => {
      if (field.value == item.email && field.parentElement.querySelector('[data-close]')) {
        field.parentElement.querySelector('[data-close]').click()
      }
    })
  }

  const cache = createCache({
    key: "css",
    prepend: false,
    container: document.head,
    speedy: true,
  })

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={vendorableMuiTheme}>
          <CacheProvider value={cache}>
            <Container disableGutters={canAddEmailToForm}>
              <Toolbar
                disableGutters={true}
              >
                <OutlinedInput
                  onChange={handleSearchChange}
                  onKeyDown={(e) => e.stopPropagation()}
                  fullWidth={true}
                  autoFocus={false}
                  inputRef={searchRef}
                  placeholder={searchPlaceholder || "Search"}
                  inputProps={{
                    'aria-label': 'search',
                  }}
                  onBlur={handleSearchBlur}
                  onFocus={handleSearchFocus}
                  onKeyPress={e => {
                    if (e && searchIsFocused && e.key === 'Enter') {
                      handleSearchSubmit(e)
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        aria-label="search"
                        size={'small'}
                        disabled={searchButtonDisabled}
                        onClick={handleSearchSubmit}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {showComparisonBar &&
                  <ComparisonBar
                    items={comparedItems}
                    handleRemoveFromComparison={removeFromComparison}
                    compareLink={compareLink}
                  />
                }
              </Toolbar>
              {loading === true &&
                <LinearProgress variant="indeterminate"/>
              }
              <List
                className="search-and-display-list"
                subheader={totalData > 0 &&
                  <ListSubheader
                    disableSticky={true}
                  >
                    { pageIndex > 0 ? `Page ${pageIndex+1} of ${totalData} result${totalData > 1 ? 's' : ''} (${took})` :
                      `Showing ${totalData} result${totalData > 1 ? 's' : ''} (${took})`
                    }
                  </ListSubheader>
                }
              >
                {data.map( (d, i) =>
                  <Box
                    mb={1}
                    key={d.id || i}
                  >
                    <Paper
                      elevation={0}
                      variant={"outlined"}
                    >
                      {d.logo ?
                        <Box
                          style={d.logo.background_color &&
                            {
                              borderTopLeftRadius: theme.spacing(0.5),
                              borderTopRightRadius: theme.spacing(0.5),
                              backgroundColor:`${d.logo.background_color}`,
                            }
                          }
                          children={
                            <Box
                              px={2}
                              py={1}
                              children={
                                 <img src={d.logo.src}/>
                              }
                            />
                          } 
                        />
                        :
                        <Box
                          px={2}
                          py={1}
                        >
                          <div style={{minHeight: "30px"}} />
                        </Box>
                      }
                      <Box>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={(d.buttons || showComparisonBar || canAddEmailToForm) ? 8 : 12}
                          >
                            <ListItem
                              className="search-and-display-list-item"
                              component="li"
                            >
                              {d.avatar &&
                                <ListItemAvatar>
                                  <Avatar
                                    alt={d.avatar.alt}
                                    src={d.avatar.src} 
                                  />
                                </ListItemAvatar>
                              }
                              {d.icon &&
                                <ListItemIcon>
                                  <Search/>
                                </ListItemIcon>
                              }
                              <div style={{maxWidth:'100%'}}>
                                {d.text &&
                                  <ListItemText
                                    primary={
                                      d.text.link ?
                                        <Link
                                          color="inherit"
                                          data-confirm={d.text.link.confirm}
                                          data-method={d.text.link.method}
                                          href={d.text.link.href}
                                          target={d.text.link.target}
                                        >
                                          {d.text.primary}
                                        </Link>
                                      :
                                        d.text.primary
                                    }
                                    secondary={d.text.secondary}
                                    secondaryTypographyProps={
                                      {
                                        style: {whiteSpace: 'pre-line'},
                                        noWrap: true,
                                      }
                                    }
                                  />
                                }
                                {d.star_rating &&
                                  <ListItemText
                                    disableTypography={true}
                                    primary={
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Icon
                                          style={{
                                            display:"flex",
                                            alignItems:"center"
                                          }}
                                        >
                                          <Star color={'primary'} />
                                        </Icon>
                                        {d.star_rating.number &&
                                          <Grid item>
                                            <Typography
                                              color="textSecondary"
                                              component="div"
                                              variant="h6"
                                            >
                                              {d.star_rating.number}
                                            </Typography>
                                          </Grid>
                                        }
                                        {d.star_rating.reviews &&
                                          <Grid item>
                                            <Typography
                                              color="textSecondary"
                                              component="div"
                                              variant="body2"
                                            >
                                              {d.star_rating.reviews}
                                            </Typography>
                                          </Grid>
                                        }
                                      </Grid>
                                    } 
                                  />
                                }
                              </div>
                              {d.controls_menu &&
                                <ListItemSecondaryAction>
                                  <ControlsMenu controls={d.controls_menu.controls} />
                                </ListItemSecondaryAction>
                              }
                            </ListItem>
                          </Grid>
                          {d.buttons &&
                            <Grid
                              item
                              xs={12}
                              md={4}
                            >
                              <Box
                                pr={2}
                                pb={1}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="flex-end"
                                >
                                  {d.buttons.map( (button, i) =>
                                    <Grid 
                                      item
                                      key={i}
                                    >
                                      <Button
                                        size="large"
                                        color={button.color}
                                        disableElevation
                                        href={button.href}
                                        onClick={button.method ? (e) => eval(button.method)(e,d) : null}
                                        variant={button.variant}
                                        disabled={button.disabled}
                                      >
                                        {button.text}
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Grid>
                          }
                          {showComparisonBar &&
                            <Grid
                              item
                              xs={12}
                              md={4}
                            >
                              <Box
                                pr={2}
                                pb={1}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="flex-end"
                                >
                                  <ComparisonToggleButton
                                    item={d}
                                    items={comparedItems}
                                    handleAddToComparison={addToComparison}
                                    handleRemoveFromComparison={removeFromComparison}
                                  />
                                </Grid>
                              </Box>
                            </Grid>
                          }
                          {canAddEmailToForm &&
                            <Grid
                              item
                              xs={12}
                              md={4}
                            >
                              <Box
                                pr={2}
                                pb={1}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="flex-end"
                                >
                                  <AddEmailToFormButton
                                    item={d}
                                    items={emailAddedToFormItems}
                                    handleAddEmailToForm={addEmailToForm}
                                    handleRemoveEmailFromForm={removeEmailFromForm}
                                    emailFieldsContainer={emailFieldsContainer}
                                  />
                                </Grid>
                              </Box>
                            </Grid>
                          }
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                )}
              </List>
              {pageCount > 1 &&
                <Pagination
                  count={pageCount}
                  page={pageIndex+1}
                  onChange={handlePageChange}
                  style={{paddingBottom: theme.spacing(4)}}
                />
              }
            </Container>
          </CacheProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

const AddEmailToFormButton = ({item, items, handleAddEmailToForm, handleRemoveEmailFromForm, emailFieldsContainer}) => {
  const [color, setColor] = useState("primary")
  const [disabled, setDisabled] = useState(false)
  const [text, setText] = useState("Add email to form")
  const [variant, setVariant] = useState("contained")

  useEffect( () => {
    const isAdded = items.some((addedItem) => addedItem.email === item.email)
    if (isAdded) {
      setColor("secondary")
      setText("Remove email from form")
      setVariant("outlined")
    } else {
      setColor("primary")
      setText("Add email to form")
      setVariant("contained")
    }
    const emailFields = Array.from(emailFieldsContainer.querySelectorAll('input[type="text"][readonly]'))
    const emails = emailFields.map(field => field.value).filter(email => email !== undefined && email !== '')
    const isDisabled = emails.includes(item.email)
    if (isDisabled) {
      setDisabled(true)
    }
  }, [items])

  const handleClick = event => {
    const isAdded = items.some((addedItem) => addedItem.email === item.email)
    if (!isAdded) {
      handleAddEmailToForm(item)
    } else {
      handleRemoveEmailFromForm(item)
    }
  }

  return (
    <Button
      size="large"
      color={color}
      disabled={disabled}
      disableElevation
      onClick={handleClick}
      variant={variant}
    >
      {text}
    </Button>
  )
}
