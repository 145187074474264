import React, { useCallback, useEffect, useState } from 'react';
import Rails from '@rails/ujs'
import { DirectUpload } from '@rails/activestorage'
import { ChonkyIconFA } from '@aperturerobotics/chonky-icon-fontawesome'
import {  defineFileAction,
          setChonkyDefaults,
          ChonkyActions,
          FileActionHandler,
          FileBrowser,
          FileContextMenu,
          FileList,
          FileToolbar,
} from '@aperturerobotics/chonky'
import { Icon, IconButton, Paper, Snackbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'
import Trix from 'trix'

setChonkyDefaults({ iconComponent: ChonkyIconFA })

export default function ChonkyBrowser(props) {
  const wrapper = props.wrapper
  const trixEditor = Boolean(wrapper.dataset.trixEditor)
  const [params, setParams] = useState({})
  const [message, setMessage] = useState('')
  const [files, setFiles] = useState([])
  const muiTheme = useTheme()
  useEffect( () => {
    Rails.ajax({
      url: wrapper.dataset.source,
      type: "get",
      dataType: 'json',
      data: Object.keys(params).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      }).join('&'),
      success(result) {
        let filesArray = []
        result.forEach( (blob) => {
          filesArray = [...filesArray, {
            id: blob.signed_id,
            attachable_sgid: blob.attachable_sgid,
            preview_image_signed_id: blob.preview_image_signed_id,
            name: blob.filename,
            content_type: blob.content_type,
            size: blob.byte_size,
            modDate: blob.created_at,
            thumbnailUrl: blob.representable ? 
                          wrapper.dataset.blobRepresentationUrlTemplate.replace(":signed_id", blob.signed_id).replace(":variation_key", blob.thumbnail_variation_key).replace(":filename", encodeURIComponent(blob.filename))
                          : null,
            previewable: blob.previewable,
            representable: blob.representable,
            variable: blob.variable,
          }]
        } )
        setFiles(filesArray)
      },
      error(result) {
        setFiles([])
      }
    })
  }, [params] )
  const attachFilesToTrixEditor = defineFileAction({
    id: 'attach_files_to_trix_editor',
    button: {
      name: 'Attach files',
      toolbar: true,
      contextMenu: true,
      tooltip: 'Attach files',
      icon: faPaperclip,
    },
    requiresSelection: true,
  })
  const disableDefaultFileActions = [
    "toggle_hidden_files",
    "toggle_show_folders_first"
  ]
  let fileActions = [
    ChonkyActions.UploadFiles,
    ChonkyActions.DownloadFiles,
    ChonkyActions.DeleteFiles,
  ]
  if (trixEditor === true) {
    fileActions = [attachFilesToTrixEditor, ...fileActions]
  }
  const handleAction = useCallback((data) => {
    if (data.id === ChonkyActions.OpenFiles.id) {
      data.state.selectedFilesForAction.forEach((file) => {
        window.open(wrapper.dataset.blobUrlTemplate.replace(":signed_id", file.id).replace(":filename", encodeURIComponent(file.name)), '_blank')
      })
    }
    else if (data.id === ChonkyActions.DeleteFiles.id) {
      data.state.selectedFilesForAction.forEach((file) => {
        Rails.ajax({
          url: `/rails/active_storage/purge_attachments/${file.id}`,
          type: "delete",
          dataType: 'json',
          data: '',
          success(result) {
            setMessage(`${file.name} deleted`)
            setFiles(prevFiles => prevFiles.filter(f => f.id !== file.id))
          },
          error(result) {}
        })
      })
    }
    else if (data.id === ChonkyActions.DownloadFiles.id) {
      const { selectedFilesForAction } = data.state
      if (selectedFilesForAction.length === 1) {
        const file = selectedFilesForAction[0]
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.onload = () => iframe.remove()
        iframe.src = wrapper.dataset.blobUrlTemplate.replace(":signed_id", file.id).replace(":filename", encodeURIComponent(file.name)).concat("?disposition=attachment")
        document.body.appendChild(iframe)
      }
      else if (selectedFilesForAction.length > 1) {
        setMessage("Preparing download")
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.onload = () => {
          setMessage("Download ready")
          iframe.remove()
        }
        iframe.src = `/rails/active_storage/stream_blobs/${selectedFilesForAction.map(file => file.id).join()}.zip`
        document.body.appendChild(iframe)
      }
    }
    else if (data.id === ChonkyActions.UploadFiles.id) {
      const form = document.getElementById('chonky-browser-upload')
      if (form === null) return
      const input = form.querySelector('input[type=file]')
      if (input === null) return
      input.addEventListener('change', (event) => {
        Array.from(input.files).forEach(file => uploadFile(file))
        Rails.fire(form, 'submit')
        input.value = null
        input.disabled = false
      })
      input.click()
      const uploadFile = (file) => {
        setMessage(`Uploading ${file.name}`)
        const url = input.dataset.directUploadUrl
        const upload = new DirectUpload(file, url)

        upload.create((error, blob) => {
          if (error) {
            setMessage(error)
          } else {
            setFiles(prevFiles => [...prevFiles, {
              id: blob.signed_id,
              attachable_sgid: blob.attachable_sgid,
              name: blob.filename,
              content_type: blob.content_type,
              size: blob.byte_size,
              modeDate: blob.created_at,
              thumbnailUrl: blob.content_type.startsWith("image/") ?
                wrapper.dataset.blobUrlTemplate.replace(":signed_id", blob.signed_id).replace(":filename", encodeURIComponent(blob.filename))
                : null,
            }])
            setMessage(`${blob.filename} uploaded`)
          }
        })
      }
    }
    else if (data.id === attachFilesToTrixEditor.id) {
      data.state.selectedFilesForAction.forEach((file) => {
        const editor = document.getElementById(wrapper.id.replace('chonky_', '')).editor
        const attachment = new Trix.Attachment({
          sgid: file.attachable_sgid,
          filename: file.name,
          filesize: file.size,
          content_type: file.content_type,
          previewable: file.representable || file.content_type.startsWith("image/") || file.content_type === "application/pdf",
          url: wrapper.dataset.blobUrlTemplate.replace(":signed_id", file.preview_image_signed_id ? file.preview_image_signed_id : file.id).replace(":filename", encodeURIComponent(file.name)),
        })
        editor.insertAttachment(attachment)
      })
    }
  }, [])

  return (
    <>
      <FileBrowser
        files={files}
        disableDefaultFileActions={disableDefaultFileActions}
        fileActions={fileActions}
        onFileAction={handleAction}
        muiThemeOptions={muiTheme}
        theme={{
          colors: {
            textActive: '#f32e74',
          },
        }}
      >
        <FileToolbar />
        <FileList />
        <FileContextMenu />
      </FileBrowser>
      <ChonkySnackbar message={message}/>
    </>
  )
}

function ChonkySnackbar({ message }) {
  const [open, setOpen] = useState(false)
  useEffect( () => {
    if (message.length > 0) {
      setOpen(true)
    }
  }, [message])
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    message = ''
    setOpen(false)
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Icon className="fa fa-times" />
        </IconButton>
      ]}
    />
  )
}
