import addressAutocomplete from "./app_components/address_autocomplete"
import addressInputComplete from "./app_components/address_input_complete"
import agentProfilesHeatmap from "./app_components/agent_profiles_heatmap"
import colourPicker from "./app_components/colour_picker"
import cocoonConfirm from "./app_components/cocoon_confirm"
import datePicker from "./app_components/date_picker"
import directUpload from "./app_components/direct_upload"
import inputClearedOnClosedByCocoon from "./app_components/input_cleared_on_closed_by_cocoon"
import inputClearedOnClosedByFoundation from "./app_components/input_cleared_on_closed_by_foundation"
import inputDuplicator from "./app_components/input_duplicator"
import inputFile from "./app_components/input_file"
import inputStepper from "./app_components/input_stepper"
import intercomStyleOverride from "./app_components/intercom_style_override"
import linkPreviewer from "./app_components/link_previewer"
import loadMore from "./app_components/load_more"
import {openCookieConsentModal} from "./app_components/cookie_consent_modal"
import {orbitSlides} from "./app_components/orbit_slides"
import purgeAttachments from "./app_components/purge_attachments"
import radioButtonsToggler from "./app_components/radio_buttons_toggler"
import radioButtonsWithBooleanAttributes from "./app_components/radio_buttons_with_boolean_attributes"
import serviceFeeCalc from "./app_components/service_fee_calc"
import signaturePad from "./app_components/signature_pad"
import sliderWordValues from "./app_components/slider_word_values"
import tabsChange from "./app_components/tabs_change"
import timeZoneSelect from "./app_components/time_zone_select"
import trixEditorAttachmentsValidation from "./app_components/trix_editor_attachments_validation"
import trixEditorToolbarCustomisation from "./app_components/trix_editor_toolbar_customisation"
import userType from "./app_components/user_type"
import warnDuplicateAddress from "./app_components/warn_duplicate_address"

import 'motion-ui'
import 'foundation-sites'

document.addEventListener("turbo:before-visit", () => {
  document.querySelector('html').classList.remove('is-reveal-open')
  if (!!document.querySelector('div.reveal-overlay[style="display: block;"]')) {
    document.querySelector('div.reveal-overlay[style="display: block;"]').removeAttribute("style")
  }
})

document.addEventListener("turbo:load", (e) => {
  addressAutocomplete(document.getElementById('addressAutocomplete'))
  addressInputComplete(document.querySelectorAll("[data-address-input-complete]"))
  agentProfilesHeatmap(document.getElementById('heatmap'))
  colourPicker(document.querySelectorAll('.colour-picker-container'))
  cocoonConfirm()
  datePicker(document.querySelectorAll('.fdatepicker'))
  directUpload(document.querySelectorAll("form[data-direct-upload='true']"))
  inputClearedOnClosedByCocoon()
  inputClearedOnClosedByFoundation(document.querySelectorAll("[data-closable]"))
  inputDuplicator(document.querySelectorAll("[data-input-duplicator]"))
  inputFile(document.querySelectorAll("input[type=file]"))
  inputStepper(document.querySelectorAll(".input-stepper"))
  intercomStyleOverride()
  linkPreviewer(document.querySelectorAll("[data-preview-link]"))
  loadMore(
    document.querySelector("[data-channel*=real_property_lease_announcements]"),
    document.querySelector("[data-load-more-path]") ? document.querySelector("[data-load-more-path]").dataset.loadMorePath : null,
    document.getElementById('loadMoreRealPropertyLeaseAnnouncements')
  )
  loadMore(
    document.querySelector("[data-channel*=real_property_sale_announcements]"),
    document.querySelector("[data-load-more-path]") ? document.querySelector("[data-load-more-path]").dataset.loadMorePath : null,
    document.getElementById('loadMoreRealPropertySaleAnnouncements')
  )
  loadMore(
    document.querySelector("[data-channel='user_notifications']"),
    '/notifications',
    document.getElementById('loadMoreUserNotifications')
  )
  loadMore(
    document.querySelector("[data-load-more-path]") ? document.querySelector("[data-load-more-path]").dataset.loadMorePath : null,
    document.getElementById('loadMoreAgents')
  )
  orbitSlides(document.getElementById('agent-inspection-gallery'))
  purgeAttachments(document.querySelectorAll("[data-purge]"))
  radioButtonsWithBooleanAttributes(document.querySelectorAll('.radio-buttons-with-boolean-attributes'))
  serviceFeeCalc(document.querySelector('[data-service-fee-calc]'))
  signaturePad(document.querySelectorAll('.signature-pad-input'))
  sliderWordValues(document.querySelectorAll('.range-slider'))
  tabsChange()
  timeZoneSelect(document.getElementById('user_time_zone'))
  trixEditorToolbarCustomisation(document.querySelectorAll('trix-editor'))
  trixEditorAttachmentsValidation()
  userType(document.getElementById("userType"))
  warnDuplicateAddress(document.getElementById('new_real_property'))
  radioButtonsToggler(document.querySelectorAll('.radio-buttons-toggler'))
  jQuery(document).foundation()
  openCookieConsentModal()
})
