export default function inputClearedOnClosedByCocoon () {
  document.addEventListener("cocoon:after-remove", (e) => {
    let node = e.detail
    if (node.querySelectorAll("input").length > 0) {
      node.querySelectorAll("input").forEach( (input) => {
        input.value = ''
        input.dispatchEvent(new Event('input', { bubbles: true }))
      })
    }
  })
}
