import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-submit"
export default class extends Controller {
  static outlets = ["pagination-submit"]
  static targets = ["selectSubmit"]

  connect() {
    this.selectSubmitTargets.forEach( (t) => {t.addEventListener("change", this.submitForm.bind(this))})
  }

  submitForm() {
    if (this.hasPaginationSubmitOutlet) {
      this.paginationSubmitOutlet.setToFirstPage()
    }
    this.element.requestSubmit()
  }
}
