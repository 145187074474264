export default function userType (el) {
  if (!el) return;

  el.querySelector('#user_type_agent').addEventListener('click', (e) => {
    el.querySelector('#user_type').value = 'Agent'
  })
  el.querySelector('#user_type_owner').addEventListener('click', (e) => {
    el.querySelector('#user_type').value = 'Owner'
  })

  let params = new URLSearchParams(window.location.search)

  if (!!params.get('user_type')) {
    if (params.get('user_type') === 'Agent' || params.get('user_type') === 'Owner' || params.get('user_type') === 'AgentAide') {
      el.classList.add('hide')
      el.querySelector('#user_type').value = params.get('user_type')
    }
  }

  if (!!params.get('user_email')) {
    document.querySelector('#user_email').value = params.get('user_email')
  }
}
