import * as React from "react"
import { createRoot } from "react-dom/client"
import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import { capitalize, namespaceCamelize } from "../helpers/string_helpers"
import * as componentsMap from '../modules/react_components_map'

// Connects to data-controller="render-react-component"
export default class extends Controller {
  connect() {
    const componentName = capitalize(namespaceCamelize(this.element.dataset.reactComponent))
    const Component = componentsMap[componentName]
    this.root = createRoot(this.element)
    this.root.render(<Component wrapper={this.element} />)
  }

  disconnect() {
    this.root.unmount()
  }
}
