import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sort-submit"
export default class extends Controller {
  static outlets = ["pagination-submit"]
  static targets = ["columns", "sortSubmit"]

  connect() {
    this.sortSubmitTargets.forEach( (t) => {t.addEventListener("change", this.submitForm.bind(this))})
  }

  sortColumn(field) {
    const inputFields = Array.from(this.columnsTarget.querySelectorAll('input'))
    const targetField = inputFields.find(input => input.name === field)

    inputFields.forEach((input) => {
      if (input !== targetField) {
        input.value = ''
      }
    })

    if (targetField) {
      const currentValue = targetField.value
      if (currentValue === '') {
        targetField.value = "asc"
      } else if (currentValue === "asc") {
        targetField.value = "desc"
      } else if (currentValue === "desc") {
        targetField.value = ''
      }
    }
    const event = new Event("change")
    targetField.dispatchEvent(event)
  }

  submitForm() {
    if (this.hasPaginationSubmitOutlet) {
      this.paginationSubmitOutlet.setToFirstPage()
    }
    this.element.requestSubmit()
  }
}
