export default function inputFile (nodeList) {
  if (nodeList.length === 0) return;
  nodeList.forEach( (node) => {
    node.addEventListener('change', (e) => {
      let filename = e.target.value.replace(/^.*[\\\/]/g, '')
      let selectedFile = document.querySelector(`.selected-file[data-input="${e.target.id}"]`)
      let removeFile = document.getElementById(e.target.id.replace(/_/, '_remove_'))
      if (!!selectedFile) {
        if (!!selectedFile.children[0]) {
          selectedFile.children[0].innerHTML = filename
        }
        else {
          selectedFile.innerHTML = filename
        }
        if (!!selectedFile.closest("[data-closable]")) {
          selectedFile.closest("[data-closable]").removeAttribute("style")
        }
      }
      if (!!removeFile) {
        removeFile.value = 0
      }
    })
    let removeButton = document.querySelector(`label[for="${node.id.replace(/_/, '_remove_')}"]`)
    if (!!removeButton) {
      removeButton.addEventListener('click', (e) => {
        let removeFile = document.getElementById(node.id.replace(/_/, '_remove_'))
        if (!!removeFile) {
          removeFile.value = 0
        }
      })
    }
  })
}
