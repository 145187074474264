import Rails from '@rails/ujs'

export default function linkPreviewer (linkList) {

  if (linkList.length === 0) return;

  linkList.forEach( (link) => {
    if (!link.tagName === 'A') { return; }
    if (!link.hasAttribute("data-preview-link")) { return; }
    if (!link.hasAttribute("href")) { return; }
    Rails.ajax({
      url: '/preview_link',
      type: "get",
      dataType: 'json',
      data: `link=${link.href}`,
      success: function(result) {
        link.parentElement.innerHTML = result.html
      },
      error: function(result) {}
    })
  })
}
