import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagination-submit"
export default class extends Controller {
  static targets = ["pageIndexField"]

  connect() {
    this.pageIndexFieldTarget.addEventListener("input", this.submitForm.bind(this))
  }

  previous(event) {
    event.preventDefault()
    const currentValue = parseInt(this.pageIndexFieldTarget.value) || 0
    if (currentValue === 0) {
      this.pageIndexFieldTarget.setAttribute('value', 0)
      this.pageIndexFieldTarget.dispatchEvent(new Event("input"))
    } else {
      this.pageIndexFieldTarget.setAttribute('value', currentValue - 1)
      this.pageIndexFieldTarget.dispatchEvent(new Event("input"))
    }
  }

  next(event) {
    event.preventDefault()
    const currentValue = parseInt(this.pageIndexFieldTarget.value) || 0
    this.pageIndexFieldTarget.setAttribute('value', currentValue + 1)
    this.pageIndexFieldTarget.dispatchEvent(new Event("input"))
  }

  goToPage(event) {
    event.preventDefault()
    const page = parseInt(event.target.dataset.page) || 1
    const pageIndex = page - 1
    this.pageIndexFieldTarget.setAttribute('value', pageIndex)
    this.pageIndexFieldTarget.dispatchEvent(new Event("input"))
  }

  setToFirstPage() {
    this.pageIndexFieldTarget.setAttribute('value', 0)
  }

  submitForm() {
    this.element.requestSubmit()
  }
}
