import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagination-search"
export default class extends Controller {
  static outlets = ["pagination-submit"]
  static targets = ["searchQueryField"]

  connect() {
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    if (
      this.hasPaginationSubmitOutlet &&
      this.element.dataset.paginationSearchQueryStore !== this.searchQueryFieldTarget.value
    ) {
      this.paginationSubmitOutlet.setToFirstPage()
      this.element.dataset.paginationSearchQueryStore = this.searchQueryFieldTarget.value
    }
  }
}
