import React, { useEffect, useRef, useState } from 'react';
import Rails from '@rails/ujs'
import {
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {
  Badge,
  Chip,
  Container,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import {
  Check,
  Close,
  FilterList,
  FirstPage,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  LastPage,
  Search,
  Update,
} from '@mui/icons-material'
import {
  StyledEngineProvider,
  ThemeProvider,
  useTheme
} from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import ReactSubTable from './react_sub_table'
import ControlsMenu from './controls_menu'
import { vendorableMuiTheme } from "./themes"

export default function ReactTable(props) {
  const wrapper = props.wrapper
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [initialOtherFilterOptions, setInitialOtherFilterOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [params, setParams] = useState({})
  const [recordsFiltered, setRecordsFiltered] = useState(0)
  const [recordsTotal, setRecordsTotal] = useState(0)
  const [updatedAt, setUpdatedAt] = useState('')
  useEffect( () => {
    const isLoading = setTimeout(() => {
      setLoading(true)
    }, 500)
    Rails.ajax({
      url: wrapper.dataset.source,
      type: "get",
      dataType: 'json',
      data: Object.keys(params).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      }).join('&'),
      success(result) {
        setColumns(
          prepareColumns(result.columns)
        )
        setData(result.data)
        setInitialOtherFilterOptions(result.initialOtherFilterOptions || [])
        setPageCount(result.pageCount)
        setRecordsFiltered(result.recordsFiltered)
        setRecordsTotal(result.recordsTotal)
        setUpdatedAt(result.updatedAt)
        clearTimeout(isLoading)
        setLoading(false)
      },
      error(result) {
        setColumns([])
        setData([])
        clearTimeout(isLoading)
        setLoading(false)
      }
    })
  }, [params] )

  const cache = createCache({
    key: "css",
    prepend: false,
    container: document.head,
    speedy: true,
  })

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={vendorableMuiTheme} >
          <CacheProvider value={cache}>
            <RenderTable
              columns={columns}
              data={data}
              initialOtherFilterOptions={initialOtherFilterOptions}
              loading={loading}
              pageCount={pageCount}
              params={params}
              setParams={setParams}
              recordsFiltered={recordsFiltered}
              recordsTotal={recordsTotal}
              updatedAt={updatedAt}
            />
          </CacheProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export function prepareColumns (columns) {
  const a = []
  columns.forEach( (c) => {
    if (c.id === 'expander') {
      a.push(
        {
          ...c,
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {isAllRowsExpanded ? 
                <IconButton
                  aria-label="contract all rows"
                  size={'small'}
                >
                  <KeyboardArrowUp />
                </IconButton> :
                <IconButton
                  aria-label="expand all rows"
                  size={'small'}
                >
                  <KeyboardArrowDown />
                </IconButton>
              }
            </span>
          ),
          Cell: ({ row }) =>
            row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                  },
                })}
              >
                {row.isExpanded ?
                  <IconButton
                    aria-label="contract row"
                    size={'small'}
                  >
                    <KeyboardArrowUp />
                  </IconButton> :
                  <IconButton
                    aria-label="expand row"
                    size={'small'}
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                }
              </span>
            ) : null,
        }
      )
    }
    else if (c.id === 'controls_menu') {
      a.push(
        {
          ...c,
          Cell: row => <ControlsMenu controls={row.row.original.controls} />,
        }
      )
    }
    else {
      a.push(
        {
          ...c,
          Cell: row => <div dangerouslySetInnerHTML={{__html: row.value}} />,
        }
      )
    }
  })
  return a
}

let toggleUpdateAlert = () => {}

function RenderTable({
  columns,
  data,
  initialOtherFilterOptions,
  loading,
  pageCount: controlledPageCount,
  params,
  setParams,
  recordsFiltered,
  recordsTotal,
  updatedAt,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, expanded },
    visibleColumns,
  } = useTable(
    {
      autoResetPage: false,
      columns,
      data,
      initialState: { hiddenColumns: ['class_name'] },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    useExpanded,
    usePagination,
  )
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [searchFilters, setSearchFilters] = useState([])
  const [otherFilterOptions, setOtherFilterOptions] = useState([])
  const [otherFilters, setOtherFilters] = useState([])
  const [filterListOpen, setFilterListOpen] = useState(false)
  const [filterListAnchorEl, setFilterListAnchorEl] = useState(null)
  const [updateTableData, setUpdateTableData] = useState(0)
  const [updateAlert, setUpdateAlert] = useState(true)
  const filterListAnchorElRef = useRef(null)
  const searchRef = useRef(null)
  const drawNumRef = useRef(0)

  useEffect( () => {
    if (otherFilters.length === 0) {
      setOtherFilterOptions(initialOtherFilterOptions)
    }
  }, [initialOtherFilterOptions] )
  
  useEffect( () => {
    const drawNum = ++drawNumRef.current
    if (drawNum === drawNumRef.current && drawNum > 1) {
      let newParams = {
        pageIndex: pageIndex,
        pageSize: pageSize,
      }
      if (sortBy.length > 0) {
        sortBy.forEach((column) => {
          const key = `columns[${column.id}][order]`
          const sortParams = {
            [key]: column.desc ? 'desc' : 'asc',
          }
          newParams = {...newParams, ...sortParams}
        })
      }
      if (searchFilters.length > 0 ) {
        newParams = { ...newParams, ...{searchFilters: searchFilters} }
      }
      if (otherFilters.length > 0 ) {
        newParams = { ...newParams, ...{otherFilters: otherFilters.map(f => f.value)} }
      }
      if (updateTableData > 0 ) {
        newParams = {...newParams, ...{updateTableData: updateTableData} }
      }
      setParams(newParams)
    }
    const trickle = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)

    return () => {
      setLoadingProgress(100)
      clearInterval(trickle)
    }
  }, [otherFilters, pageIndex, pageSize, searchFilters, sortBy, updateTableData] )

  const handleUpdateTableData = event => {
    setUpdateTableData(updateTableData + 1)
    toggleUpdateAlert(true)
  }

  toggleUpdateAlert = invisible => {
    setUpdateAlert(invisible)
  }

  const handlePageChange = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleRowsPerPageChange = event => {
    setPageSize(Number(event.target.value))
  }

  const toggleFilterListOpen = event => {
    if ( filterListOpen === true ) {
      setFilterListOpen(false)
      setFilterListAnchorEl(null)
    }
    else {
      setFilterListAnchorEl(filterListAnchorElRef.current)
      setFilterListOpen(true)
    }
  }

  const handleSearchSubmit = event => {
    if (searchRef.current.value === "") {
      return
    }
    const newSearchFilter = searchRef.current.value.trim()
    setSearchFilters(prev => {
      return [...prev, ...[newSearchFilter]]
    })
    searchRef.current.value = ''
    gotoPage(0)
  }

  const handleDeleteSearchFilterChip = (chip) => () => {
    setSearchFilters(prev => prev.filter(f => f !== chip))
    gotoPage(0)
  }

  const handleClickOtherFilterOptionChip = (option) => () => {
    setOtherFilters( prev => {
      return [...prev, ...[option]]
    })
    setOtherFilterOptions(prev => prev.filter(o => o !== option))
    gotoPage(0)
  }

  const handleDeleteOtherFilterChip = (chip) => () => {
    setOtherFilterOptions( prev => {
      return [...prev, ...[chip]]
    })
    setOtherFilters(prev => prev.filter(f => f !== chip))
    gotoPage(0)
  }

  return (
    <Paper square={true} variant={'outlined'}>
      <TableContainer>
        <Toolbar disableGutters={true}>
          <Grid container>
            <Grid item xs={2}>
              <IconButton
                aria-label="filters"
                title="Filter Table"
                onClick={toggleFilterListOpen}
              >
                <FilterList />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              {searchFilters.map( (chip, i) =>
                <Chip
                  icon={<Search />}
                  key={i}
                  label={chip}
                  onDelete={handleDeleteSearchFilterChip(chip)}
                  style={{margin:'4px'}}
                />
              )}
              {otherFilters.map( (chip, i) =>
                <Chip
                  icon={<Check />}
                  key={i}
                  label={chip.label}
                  onDelete={handleDeleteOtherFilterChip(chip)}
                  style={{margin:'4px'}}
                />
              )}
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item xs={2}
            >
              <Grid item>
                <IconButton
                  aria-label="update"
                  title="Update Table Data"
                  onClick={handleUpdateTableData}
                  disabled={loading}
                >
                  <Badge
                    badgeContent={"!"}
                    color="primary"
                    invisible={updateAlert}
                  >
                    <Update />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
            {updatedAt.length > 0 &&
              <Grid
                container
                justifyContent="flex-end"
                spacing={1}
                item xs={12}
              >        
                <Typography variant="caption" title="Last Updated">
                  {updatedAt}
                </Typography>
              </Grid>
            }
          </Grid>
        </Toolbar>
        <Menu
          anchorEl={filterListAnchorEl}
          open={filterListOpen}
          onClose={toggleFilterListOpen}
        >
          <Container>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h6">
                  Filters
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" item xs={2}>
                <IconButton
                  aria-label="close"
                  onClick={toggleFilterListOpen}
                  size={'small'}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Grid container>
              <Grid item xs={10}>
                <InputBase
                  onKeyDown={(e) => e.stopPropagation()}
                  fullWidth={true}
                  inputRef={searchRef}
                  placeholder="Search table"
                  inputProps={{ 'aria-label': 'search table' }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleSearchSubmit()
                    }
                  }}
                />
              </Grid>
              <Grid container justifyContent="flex-end" item xs={2}>
                <IconButton
                  type="submit"
                  aria-label="search"
                  size={'small'}
                  disabled={searchRef.current === null || searchRef.current.value === ""}
                  onClick={handleSearchSubmit}
                >
                  <Search />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
          <Container
            maxWidth={'xs'}
          >
            {otherFilterOptions.map( (option, i) =>
              <Chip
                key={i}
                label={option.label}
                onClick={handleClickOtherFilterOptionChip(option)}
                style={{margin:'4px'}}
                variant={'outlined'}
              />
            )}
          </Container>
        </Menu>
        <Table {...getTableProps()}>
          <TableHead ref={filterListAnchorElRef}>
            {headerGroups.map(headerGroup => (
              <TableRow
                style={{
                  borderTop:'1px solid rgba(224, 224, 224, 1)',
                  verticalAlign:'top'
                }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: column.canSort ? `Sort ${column.Header}` : undefined
                      })
                    )}
                  >
                    {column.render('Header')}
                    {column.canSort &&
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={visibleColumns.length}
                padding={'none'}
                style={{borderBottom:'none'}}
              >
                {loading === true &&
                  <LinearProgress variant="determinate" value={loadingProgress} />
                }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let rowProps = row.getRowProps()
              if (row.original.subTable && row.original.subTable.data.length > 0) {
                row.canExpand = true
              }
              return (
                <React.Fragment key={rowProps.key}>
                  <TableRow {...rowProps} className={row.original.class_name}>
                    {row.cells.map(cell => {
                      return(
                        <TableCell{...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {(row.isExpanded && row.original.subTable && row.original.subTable.data.length > 0) ?
                    <ReactSubTable
                      columns={row.original.subTable.columns}
                      data={row.original.subTable.data}
                      visibleColumnsNum={visibleColumns.length}
                    />
                    :
                    null
                  }
                </React.Fragment>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  1,
                  2,
                  5,
                  10,
                  25,
                  50,
                  100,
                ]}
                colSpan={columns.length}
                count={recordsTotal}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                  variant: 'outlined',
                }}
                style={{borderBottom:'none'}}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme()

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  )
}
export { toggleUpdateAlert }
