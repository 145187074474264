import 'jquery'

export default function inputClearedOnClosedByFoundation (nodeList) {
  nodeList.forEach( (node) => {
    jQuery(node).on("closed.zf", (e) => {
      if (node.querySelectorAll("input").length > 0) {
        node.querySelectorAll("input").forEach( (input) => {
          input.value = ''
          input.dispatchEvent(new Event('input', { bubbles: true }))
        })
      }
    })
  })
}
