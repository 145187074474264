import { useEffect, useRef, useState } from 'react';

export default function useOnScreen(
  ref,
  {
    root = null,
    rootMargin = "0px",
    threshold = 1.0,
  } = {}
) {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const observerRef = useRef(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting)
      },
      {
        root: root,
        rootMargin: rootMargin,
        threshold: threshold,
      },
    )
  }, [])

  useEffect(() => {
    if (ref.current) {
      observerRef.current.observe(ref.current)

      return () => {
        observerRef.current.disconnect()
      }
    }
  }, [ref.current])

  return isOnScreen
}
