import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagination-controls"
export default class extends Controller {
  static outlets = ["pagination-submit"]

  previous(event) {
    this.paginationSubmitOutlet.previous(event)
  }

  next(event) {
    this.paginationSubmitOutlet.next(event)
  }

  goToPage(event) {
    this.paginationSubmitOutlet.goToPage(event)
  }
}
