import 'jquery'

export default function sliderWordValues (sliderList) {

  if (sliderList.length === 0) return;

  sliderList.forEach( (slider) => {
    let wordValues = slider.querySelector("[data-word-values]").dataset.wordValues.split(",")
    jQuery(slider.querySelector("[data-slider]")).on('moved.zf.slider', () => {
      slider.querySelector("[data-word-values]").innerHTML = wordValues[slider.querySelector('input').value-1]
    })
  })
}
