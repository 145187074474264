import React, { useMemo, useState } from 'react';
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  MoreVert,
} from '@mui/icons-material'
import ContentDialog from './content_dialog'

export default function ControlsMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const controls = useMemo( () => {
    return props.controls
  }, [props.controls])
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div style={{textAlign: 'right'}}>
      <IconButton
        aria-label="controls menu"
        aria-controls="controls-menu"
        aria-haspopup="true"
          onClick={handleClick}
        size={'small'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="controls-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 216,
            width: '20ch',
          },
        }}
      >
        {controls.map((control) => (
          <div key={control.label}>
            {control.modal !== undefined ? 
              <ContentDialog content={control.modal}>
                <Link
                  color="inherit"
                  data-confirm={control.confirm}
                  data-method={control.method}
                  href={control.link}
                  target={control.target}
                >
                  <MenuItem onClick={handleClose}>
                    {control.label}
                  </MenuItem>
                </Link>
              </ContentDialog>
            : <Link
                color="inherit"
                data-confirm={control.confirm}
                data-method={control.method}
                href={control.link}
                target={control.target}
              >
                <MenuItem onClick={handleClose}>
                  {control.label}
                </MenuItem>
              </Link>
            }
          </div>
        ))}
      </Menu>
    </div>
  )
}
