import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  connect() {
    const loader = new Loader({
      apiKey: "AIzaSyA6Pv_YDs9HxqESCCYmtYuyiZomxMCNypY",
      version: "weekly",
    })
    loader.load().then(async () => {
      const node = this.element
      let options =  {
        center: {lat: parseFloat(node.dataset.lat), lng: parseFloat(node.dataset.lng)},
        styles: [
          {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
          },
          {
              featureType: 'poi.park',
              elementType: 'labels',
              stylers: [{ visibility: 'on' }]
          }
        ],
        zoom: parseInt(node.dataset.zoom, 10) || 14,
      }

      if (node.classList.contains('static')) {
        Object.assign(options, {
          draggableCursor: 'unset, auto;',
          draggingCursor: 'unset, auto;',
          fullscreenControl: false,
          gestureHandling: 'none',
          mapTypeControl: false,
          scrollwheel: false,
          streetViewControl: false,
          zoomControl: false,
        })
      }
      const { Map } = await google.maps.importLibrary("maps")
      const map = new Map(node, options)

      const markerSVG = {
        path: 'M1152 640q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm256 0q0 109-33 179l-364 774q-16 33-47.5 52t-67.5 19-67.5-19-46.5-52l-365-774q-33-70-33-179 0-212 150-362t362-150 362 150 150 362z',
        fillColor: '#f32e74',
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new google.maps.Point(870,1650),
        scale: (0.02, 0.02)
      }
      const { Marker } = await google.maps.importLibrary("marker")
      const mapMarker = new Marker({
        cursor: 'unset',
        icon: markerSVG,
        map: map,
        position: map.getCenter(),
      })
    })
  }
}
