import consumer from "./consumer"
import { toggleUpdateAlert } from "../modules/react_components/react_table"

document.addEventListener("turbo:load", () => {
  if (!!document.querySelector("[data-channel*=announcements]")) {
    let job_type = document.querySelector("[data-channel*=announcements]").dataset.channel.split("_announcements:")[0]
    let job_id = document.querySelector("[data-channel*=announcements]").dataset.channel.split("_announcements:")[1]
    
    consumer.subscriptions.create({channel: 'AnnouncementsChannel', job_type: job_type, job_id: job_id}, {
      initialized() {
        // Called once when the subscription is created.
      },

      connected() {
        // Called when the subscription is ready for use on the server
        
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data !== undefined) {
          if (!!document.querySelector(`[data-channel='${data.job_type}_announcements:${data.job_id}']`)){
            const template = document.createElement("div")
            template.innerHTML = data.html
            document.querySelector(`[data-channel='${data.job_type}_announcements:${data.job_id}']`).prepend(template.firstElementChild)
            if (!!document.getElementById(`${data.job_type}_bids_react_table`) && data.announceable_type.includes("Bid")) {
              toggleUpdateAlert(false)
            }
          }
        }
      },

    })
  }
})
