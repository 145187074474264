import React, { useEffect, useRef, useState } from 'react';
import Rails from '@rails/ujs'
import {
  Avatar,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Portal,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  CheckCircle,
  KeyboardArrowLeft,
  RemoveCircle,
} from '@mui/icons-material'
import {
  StyledEngineProvider,
  ThemeProvider,
  useTheme
} from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import ControlsMenu from './controls_menu'
import { SlideUpTransition } from './transitions'
import { vendorableMuiTheme } from "./themes"

export default function NoteR(props) {
  const wrapper = props.wrapper
  const [notes, setNotes] = useState([])
  const [newNoteButton, setNewNoteButton] = useState('')
  const [notesParams, setNotesParams] = useState({})
  const [notesLoading, setNotesLoading] = useState(false)
  const [notesIndex, setNotesIndex] = useState()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [note, setNote] = useState({})
  const [noteURL, setNoteURL] = useState('') 
  const [noteParams, setNoteParams] = useState({})
  const [noteLoading, setNoteLoading] = useState(false)
  const [noteHeader, setNoteHeader] = useState('')
  const notesRef = useRef(null)
  const dialogRef = useRef(null)
  const theme = useTheme()
  const displayFullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect( () => {
    getNotes()
  }, [notesParams])

  useEffect( () => {
    if (noteURL.length > 0) {
      getNote(noteURL)
    }
  }, [noteURL])

  useEffect( () => {
    if (displayFullScreen) {
      handleDialogClose()
    }
  }, [displayFullScreen])

  const getNotes = () => {
    const isLoading = setTimeout(() => {
      setNotesLoading(true)
    }, 500)
    Rails.ajax({
      url: wrapper.dataset.source,
      type: "get",
      dataType: 'json',
      data: Object.keys(notesParams).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(notesParams[key])}`
      }).join('&'),
      success(result) {
        setNotes(
          result.notes.map(n => (
            {
              created_at: n.created_at,
              approved_at: n.approved_at,
              title: n.title,
              notable_id: n.notable_id,
              notable_type: n.notable_type,
              snippet: n.snippet,
              url: n.url,
            }
          ))
        )
        setNewNoteButton(result.new_note_button)
        clearTimeout(isLoading)
        setNotesLoading(false)
      },
      error(result) {
        setNotes([])
        clearTimeout(isLoading)
        setNotesLoading(false)
      }
    })
  }

  const getNote = (url) => {
    const isLoading = setTimeout(() => {
      setNoteLoading(true)
    }, 500)
    Rails.ajax({
      url: url,
      type: "get",
      dataType: 'json',
      data: Object.keys(noteParams).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(noteParams[key])}`
      }).join('&'),
      success(result) {
        setNoteLoading(false)
        clearTimeout(isLoading)
        setNote(
          result.note
        )
      },
      error(result) {
        setNoteLoading(false)
        setNote({})
        clearTimeout(isLoading)
      }
    })
  }

  const handleNotesItemClick = (event, index) => {
    setNote({})
    setNoteParams({})
    setNotesIndex(index)
    setNoteURL(notes[index].url)
    setNoteHeader(notes[index].title)
    if (displayFullScreen) {
      setDialogOpen(true)
    }
  }

  const handleDialogClose = () => {
    setNote({})
    setNotesIndex()
    setNoteURL('')
    setNoteHeader('')
    setDialogOpen(false)
    if (notesRef.current) {
      notesRef.current.scrollIntoView()
    }
  }

  const cache = createCache({
    key: "css",
    prepend: false,
    container: document.head,
    speedy: true,
  })

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={vendorableMuiTheme} >
          <CacheProvider value={cache}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <div
                  ref={notesRef}
                  dangerouslySetInnerHTML={{__html: newNoteButton}}
                >
                </div>    
                <List
                  className="note-r-notes-list"
                >
                  <Divider component="li"/>
                  {notesLoading === true &&
                    <LinearProgress variant="indeterminate"/>
                  }
                  {notes.map( (n, i) =>
                    <ListItem
                      button
                      className="note-r-notes-list-item"
                      component="li"
                      disabled={notesIndex === i}
                      divider={true}
                      key={`${n.notable_type}_${n.notable_id}_${i}`}
                      onClick={(event) => handleNotesItemClick(event, i)}
                      selected={notesIndex === i}
                    >
                      <div style={{maxWidth:'100%'}}>
                        <ListItemText
                          disableTypography={true}
                          primary={
                            <Typography
                              color="textSecondary"
                              component="div"
                              variant="body2"
                            >
                              {n.created_at}
                            </Typography>
                          }
                        />
                        <ListItemText
                          primary={n.title}
                          primaryTypographyProps={{
                            noWrap: true,
                          }}
                          secondary={n.snippet}
                          secondaryTypographyProps={{
                            noWrap: true,
                          }}
                        />
                      </div>
                      <ListItemSecondaryAction>
                        {n.approved_at !== null ?
                          <CheckCircle
                            style={{ color: '#2d9691' }}
                            fontSize={'small'}
                          />
                        : <RemoveCircle
                            style={{ color: '#ffae00' }}
                            fontSize={'small'}
                          />
                        }
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {notes.length === 0 &&
                    <ListItem>
                      <ListItemText
                        primary={"You do not have any notes"}
                      />
                    </ListItem>
                  }
                </List>
              </Grid>
              <Grid item xs={12} md={8}>
                <Portal
                  container={dialogRef.current}
                  disablePortal={!displayFullScreen}
                >
                  {notesIndex !== undefined &&
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={6}>
                          {!displayFullScreen &&
                            <Typography
                              color="textPrimary"
                              component="div"
                              variant="body1"
                            >
                              {noteHeader}
                            </Typography>
                          }
                        </Grid>
                        {note.controls !== undefined &&
                          <Grid item xs={6}>
                            {!displayFullScreen &&
                              <ControlsMenu controls={note.controls} />
                            }
                          </Grid>
                        }
                      </Grid>
                    </CardContent>
                  }
                  <div
                    className="note-r-note-display"
                  >
                    {Object.keys(note).length > 0 &&
                      <div>
                        {noteLoading === true &&
                          <LinearProgress variant="indeterminate"/>
                        }
                        <Paper variant="outlined">
                          <CardContent>
                            <Typography variant="h4" component="h3" gutterBottom={true}>
                              {note.title}
                            </Typography>
                            <Grid container spacing={2} alignItems={'center'}>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.created_by_label}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} container spacing={1} alignItems={'center'}>
                                <Grid item>
                                  <Avatar
                                    alt={note.created_by.name}
                                    src={note.created_by.avatar} 
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="textPrimary"
                                    component="div"
                                    variant="body2"
                                  >
                                    {note.created_by.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.created_at_label}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={10}>
                                <Typography
                                  color="textPrimary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.created_at}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.updated_at_label}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={10}>
                                <Typography
                                  color="textPrimary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.updated_at}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.approved_by_label}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} container spacing={1} alignItems={'center'}>
                                <Grid item>
                                  {note.approved_by.avatar !== null &&
                                    <Avatar
                                      alt={note.approved_by.name}
                                      src={note.approved_by.avatar} 
                                    />
                                  }
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="textPrimary"
                                    component="div"
                                    variant="body2"
                                  >
                                    {note.approved_by.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  variant="body2"
                                >
                                  {note.approved_at_label}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} container spacing={1} alignItems={'center'}>
                                <Grid item>
                                  <Typography
                                    color="textPrimary"
                                    component="div"
                                    variant="body2"
                                  >
                                    {note.approved_at}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {note.approved_at !== null &&
                                    <CheckCircle style={{ color: '#2d9691' }}/>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardContent dangerouslySetInnerHTML={{__html: note.content}} />
                        </Paper>
                      </div>
                    }
                  </div>
                </Portal>
              </Grid>
            </Grid>
            <Dialog
              fullScreen
              open={dialogOpen}
              onClose={handleDialogClose}
              TransitionComponent={SlideUpTransition}
            >
              <DialogTitle>
                <Grid container alignItems={"center"}>
                  <Grid item xs={2}>
                    <IconButton aria-label="close" onClick={handleDialogClose}>
                      <KeyboardArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      color="textPrimary"
                      component="div"
                      variant="body1"
                    >
                      {noteHeader}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {note.controls !== undefined &&
                      <ControlsMenu controls={note.controls} />
                    }
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent ref={dialogRef} />
            </ Dialog>
          </CacheProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}
