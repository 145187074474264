import Rails from '@rails/ujs'

export default function warnDuplicateAddress (form) {

  if (!form) return;

  function isAddressSameWithinTeam(callback) {
    var   team_id = document.getElementById('real_property_team_id').value,
          unit_number = document.getElementById('subpremise').value,
          street_number = document.getElementById('street_number').value,
          street_name = document.getElementById('route').value,
          suburb = document.getElementById('locality').value,
          state = (document.getElementById('administrative_area_level_1') || {}).value || "",
          postal_code = document.getElementById('postal_code').value,
          country = document.getElementById('country').value,
          url = '/addresses/is_address_same_within_team';
    Rails.ajax({
      url: url,
      type: "post",
      dataType: 'json',
      data: 'team_id='+team_id+
            '&unit_number='+unit_number+'&street_number='+street_number+
            '&street_name='+street_name+'&suburb='+suburb+'&state='+state+
            '&postal_code='+postal_code+'&country='+country,
      success: function(result) {
        callback(result, confirmationBox)
      },
      error: function(result) {}
    })
  }

  function confirmDuplicate(result, callback) {
    if (result === true) { callback() }
    else {
      submitForm()
    }
  }

  function confirmationBox() {
    if (Rails.confirm("The property you are about to save has already been created within your team. Are you sure you wish to continue?") == true){
      submitForm()
    }
  }

  function submitForm() {
    form.submit()
  }

  form.addEventListener('submit', (e) => {
    isAddressSameWithinTeam(confirmDuplicate)
    e.preventDefault()
  })
}
